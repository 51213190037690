import { useAuth0 } from "@auth0/auth0-react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Footer } from "../components/Footer";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setHouseRules } from "../redux/slices/settingsSlice";
import { DataBaseProvider } from "../utilities/DatabaseProvider";
export interface IHouseRule {
    name: string;
    description: string;
    type: string;
}
const HouseRules = [
    {
        name: "Shield Buff", description: "Shields give +2 to armour save when in combat (when wielded with a one-handed weapon).", type: "Equipment"
    },
    {
        name: "Buckler Buff", description: "Bucklers give a +1 to armour save when in combat (when wielded with a one-handed weapon).", type: "Equipment"
    },
    {
        name: "Hammer Nerv", description: "Increase the cost of Hammers/Maces/Clubs to 5 gc. Introduce a new item, 'Baton'. It costs 3 gc, and is like a Hammer/Mace but without the Concussion special rule.", type: "Equipment"
    },
    {
        name: "Sling Nerv", description: "Slings are S2 and range 16\".", type: "Equipment"
    },
    {
        name: "Cheap Armour", description: "Suits of Armour cost 50% less.", type: "Equipment"
    },
    {
        name: "Unhandy Crossbows", description: "When firing a crossbow you cannot use the Quickshot skill.", type: "Equipment"
    },
    {
        name: "Deadly Handguns", description: "Handguns gain +1 to injury rolls.", type: "Equipment"
    },
    {
        name: "Revised Helmets", description: "When wearing a Helmet, the warrior treats an injury roll of 1-3 as knocked down.", type: "Equipment"
    },
    {
        name: "Mass Shootings", description: "Only 50% of your warband members may be equipped with ranged weapons.", type: "Campaign"
    },
    {
        name: "Spear fix", description: "Spears will always Strike First the first round of combat, even when charged.", type: "Equipment"
    },
    {
        name: "Elevate Short Bows", description: "Increase the range of Short Bows to 20\".", type: "Equipment"
    },
    {
        name: "Unique Talismans", description: "Rabbits Foot, Lucky Charm & Holy/Unholy Relics are limited to 1 per warband.", type: "Equipment"
    },
    {
        name: "Cheap Guns", description: "Blackpowder weapon prices are reduced by a third (rounding up).", type: "Equipment"
    },
    {
        name: "We will be Heroes", description: "All heroes may search for Wyrdstones, even if they went Out of Action, as long as they survive the lasting injury rolls.", type: "Campaign"
    },
    {
        name: "No Rot", description: "Nurgles Rot is banned.", type: "Campaign"
    },
    {
        name: "Unique Characters", description: "Each skill may only be learned once per warband.", type: "Campaign"
    },
    {
        name: "Stumbling Charges", description: "When failing a charge, the warrior does not stand still but stumbles forward 1D6\" (but cannot end its movement closer than 1\" to an enemy model).", type: "Campaign"
    },
    {
        name: "Procession of Morr", description: "Every warband gains a free Priest of Morr. This Priest does not afect the warband rating at all, nor do they contribute ot the max. amount of warriors a warband can have. Their equipment cannot change and they do not gain experience. They also do not affect income from selling shards. They do however contribute to the warband size when making rout tests.", type: "Campaign"
    }
];
const Compo = ({ houseRule, isActive, toggleActive }: { houseRule: IHouseRule; isActive: boolean; toggleActive: (hrName: string) => void }) => {
    return <div key="dummy-key" style={{width: "100%", borderBottom: "1px solid var(--color-light)", padding: "0.2em"}} className="skill-selection-item-container" onClick={() => { toggleActive(houseRule.name); }} >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div key="dummy-key" className="">{houseRule.name}</div>
            <div key="dummy-key" className="skill-selection-item">{houseRule.description}</div>
        </div>
        <FontAwesomeIcon icon={isActive ? faCheck : faSquare as IconProp} className="skill-selection-icon" />
    </div>;
};
export const ProfilePage = () => {
    const { user, logout } = useAuth0();
    const dispatch = useAppDispatch();
    const settings = useAppSelector(state => state.settings);
    const [selectedHouseRules, setSelectedHouseRules] = useState<string[]>([...settings.houseRules]);
    const toggleActive = (hrName: string) => {
        if (selectedHouseRules.find((selected) => selected === hrName)) {
            setSelectedHouseRules(selectedHouseRules.filter((selected) => selected !== hrName));
        } else {
            setSelectedHouseRules([...selectedHouseRules, hrName]);
        }
    };
    const isActive = (item: string) => !!selectedHouseRules?.find((selected) => selected === item);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const submitAction = async () => {
        dispatch(setHouseRules(selectedHouseRules));
        const DatabaseProviderInstance = await DataBaseProvider.getInstance();
        const response = await DatabaseProviderInstance.saveSettings({ ...settings, houseRules: selectedHouseRules }, user?.email || "");
        if (response.success) {
            setShowSuccess(true);
        }
    };
    return <>
        <h2 >User information</h2>
        <div className="content-container">
            <div style={{ padding: "1em" }}>{user?.sub} / {user?.email}</div>
            <div className="button" onClick={() => logout()}>Logout</div>
        </div>
        <div className="content-container">
        <h2>Personal settings</h2>
        <div className="header-1">Theming</div>
        <div>nothing implemented yet...</div>
        <div className="header-1">House Rules</div>
        {HouseRules.map((houseRule) => <Compo key={houseRule.name} houseRule={houseRule} isActive={isActive(houseRule.name)} toggleActive={toggleActive} />)}
        <div className="button" onClick={() => submitAction()}>Submit settings</div>
        {showSuccess ? <div style={{display: "flex"}}><FontAwesomeIcon className="input-icon-ok" icon={faCircleCheck} /><div style={{paddingLeft: "1em"}}>Settings saved successfully.</div></div> : null}
        </div>
        <Footer />
    </>;
};
