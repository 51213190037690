import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import { AddWarriorPage } from "./pages/AddWarrior";
import { LandingPage } from "./pages/LandingPage";
import { PdfPage } from "./pages/PdfPage";
import { WarbandOverviewPage } from "./pages/WarbandOverview";
import { store } from "./redux/store";
import { Auth0ProviderWithHistory } from "./utilities/AuthProvider";
import { LoginPage } from "./pages/LoginPage";
import { MaintaintWarriorEquipmentPage } from "./pages/MaintainEquipmentPage";
import { MaintainWarriorHeadPage } from "./pages/MaintainHeadPage";
import { ProfilePage } from "./pages/ProfilePage";
import { MaintaintWarriorRulesPage } from "./pages/MaintainRulesPage";
import { MaintaintWarriorInjuriesPage } from "./pages/MaintainInjuriesPage";
import { MaintaintWarriorSpellsPage } from "./pages/MaintainSpellsPage";
import { DeleteWarriorPage } from "./pages/DeleteWarriorPage";
import { PromoteWarriorPage } from "./pages/PromoteWarriorPage";
import { TradingPostPage } from "./pages/TradingPostPage";
import { EditWarbandPage } from "./pages/EditWarbandPage";
import { AddHiredSwordPage } from "./pages/AddHiredSword";
import { MaintainExperiencePage } from "./pages/MaintainExperiencePage";
import { AddPriestOfMorrPage } from "./pages/AddPriestOfMorr";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () =>

  <HashRouter basename={`/${process.env.PUBLIC_URL}`}>
    <Auth0ProviderWithHistory>
      <div className="main-content">
        <div className='container'>
          <Routes>
            <Route path="/overview" Component={WarbandOverviewPage} />
            <Route path="/" Component={LoginPage} />
            <Route path="/profile" Component={ProfilePage} />
            <Route path="/start" Component={LandingPage} />
            <Route path="/add-warrior" Component={AddWarriorPage} />
            <Route path="/add-hiredsword" Component={AddHiredSwordPage} />
            <Route path="/warrior-equipment" Component={MaintaintWarriorEquipmentPage} />
            <Route path="/warrior-main" Component={MaintainWarriorHeadPage} />
            <Route path="/warrior-rules" Component={MaintaintWarriorRulesPage} />
            <Route path="/print-pdf" Component={PdfPage} />
            <Route path="/warrior-injuries" Component={MaintaintWarriorInjuriesPage} />
            <Route path="/warrior-spells" Component={MaintaintWarriorSpellsPage} />
            <Route path="/warrior-delete" Component={DeleteWarriorPage} />
            <Route path="/warrior-promote" Component={PromoteWarriorPage} />
            <Route path="/trading-post" Component={TradingPostPage} />
            <Route path="/edit-warband" Component={EditWarbandPage} />
            <Route path="/warrior-experience" Component={MaintainExperiencePage} />
            <Route path="/priest-of-morr" Component={AddPriestOfMorrPage} />
          </Routes>
        </div>
      </div>
    </Auth0ProviderWithHistory>
  </HashRouter>;


root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
