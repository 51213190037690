import { UnknownAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ICombinedEquipment } from "../../types/database";
import { IWarband } from "../../types/warband";
import { initialWarrior, IWarrior } from "../../types/warrior";
import { addWarbandLog, addWarrior, resetLog, loadWarrior, changeFunds, resetDelta, updateWarrior, removeWeapon, addWeapon, increaseXP } from "../slices";
import { addDelta, IDelta } from "../slices/deltaSlice";
import { addLog, ILogEntry } from "../slices/logSlice";

export const addWarriorSaga = (
    templog: ILogEntry[], 
    warrior: IWarrior, 
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addWarbandLog([...templog, { command: "Add warrior", value: `${warrior.Name} | ${warrior.HeadCount > 1 ? `${warrior.HeadCount}x ` : ""}${warrior.WarriorType}`, cost: warrior.Cost }]));
    dispatch(addWarrior(warrior));
    dispatch(resetLog());
    dispatch(loadWarrior(initialWarrior));
    dispatch(resetDelta());
};

export const buyWarriorSaga = (
    templog: ILogEntry[], 
    warrior: IWarrior, 
    deltaFunds: number,
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addWarbandLog([...templog, { command: "Add warrior", value: `${warrior.Name} | ${warrior.HeadCount > 1 ? `${warrior.HeadCount}x ` : ""}${warrior.WarriorType}`, cost: warrior.Cost }]));
    dispatch(addWarrior(warrior));
    dispatch(resetLog());
    dispatch(loadWarrior(initialWarrior));
    dispatch(changeFunds(-deltaFunds));
    dispatch(resetDelta());
};

export const updateWarriorSaga = (
    templog: ILogEntry[], 
    warrior: IWarrior, 
    deltaFunds: number,
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addWarbandLog([...templog, { command: "Add warrior", value: `${warrior.Name} | ${warrior.HeadCount > 1 ? `${warrior.HeadCount}x ` : ""}${warrior.WarriorType}`, cost: warrior.Cost }]));
    dispatch(updateWarrior(warrior));
    dispatch(resetLog());
    dispatch(loadWarrior(initialWarrior));
    dispatch(changeFunds(-deltaFunds));
    dispatch(resetDelta());
};

export const sellWeaponSaga = (
    equi: ICombinedEquipment, 
    warrior: IWarrior, 
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addLog({ command: "Sell Equipment", value: equi.EquipmentName, cost: -(Math.ceil(equi.Price / 2) * warrior.HeadCount) }));
    dispatch(removeWeapon(equi));
    dispatch(addDelta({ command: "Sell Equipment", value: -(Math.ceil(equi.Price / 2) * warrior.HeadCount) }));
};

export const returnWeaponSaga = (
    equi: ICombinedEquipment, 
    warrior: IWarrior, 
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addLog({ command: "Return Equipment", value: equi.EquipmentName, cost: -(equi.Price * warrior.HeadCount) }));
    dispatch(removeWeapon(equi));
    dispatch(addDelta({ command: "Return Equipment", value: -(equi.Price * warrior.HeadCount) }));
};

export const removeWeaponSaga = (
    equi: ICombinedEquipment, 
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addLog({ command: "Remove Equipment", value: equi.EquipmentName }));
    dispatch(removeWeapon(equi));
};

export const buyWeaponSaga = (
    equi: ICombinedEquipment, 
    warrior: IWarrior, 
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addLog({ command: "Buy Equipment", value: equi.EquipmentName, cost: equi.Price * warrior.HeadCount }));
    dispatch(addWeapon(equi));
    dispatch(addDelta({ command: "Buy Equipment", value: equi.Price * warrior.HeadCount }));
};

export const cancelWarriorSaga = (dispatch: ThunkDispatch<unknown, undefined, UnknownAction>) => {
    dispatch(loadWarrior(initialWarrior));
    dispatch(resetLog());
    dispatch(resetDelta());
};

export const increaseExperienceSaga = (
    reason: string, 
    warrior: IWarrior, 
    dispatch: ThunkDispatch<{
        tempwarrior: IWarrior;
        warband: IWarband;
        delta: IDelta[];
        templog: ILogEntry[];
    }, undefined, UnknownAction>
) => {
    dispatch(addLog({ command: "Increase experience", value: `${warrior.Name} got 1 xp for ${reason}` }));
    dispatch(increaseXP());
};