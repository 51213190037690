import { ISettings } from "../redux/slices/settingsSlice";
import { ICombinedEquipment, IDatabaseEquipment, IDatabaseTradingPostItem } from "../types/database";
import { HeroType, IWarrior } from "../types/warrior";

export const weaponInPossession = (weapon: ICombinedEquipment, warrior: IWarrior) => warrior.Equipment?.find((equi) => equi.EquipmentName === weapon.EquipmentName);
export const maxWeaponInPossession = (equipment: ICombinedEquipment, warrior: IWarrior) => {
    const currentQuantity = weaponInPossession(equipment, warrior)?.Quantity || 0;
    switch (equipment.EquipmentType) {
        case "Melee":
            return currentQuantity > 1;
        case "Ranged":
            return currentQuantity > 0;
        case "Wargear":
            return currentQuantity > 0;
        default:
            throw new Error(`Unknown equipment type: ${equipment.EquipmentType}`);
    }
};

export const isDisabledEquipment = (weapon: ICombinedEquipment, warrior: IWarrior) => {
    const weaponOnWarrior = warrior.Equipment?.find((equi) => equi.EquipmentName === weapon.EquipmentName);
    const numberOfWeaponsOfSameType = warrior.Equipment?.reduce((acc, equi) => {
        if (equi.EquipmentType === weapon.EquipmentType) {
            acc += equi.Quantity;
        }
        return acc;
    }, 0) || 0;
    if (weaponOnWarrior) {
        // TODO: make traits an array
        if (weapon.Traits.includes("Two-handed") || weapon.Traits.includes("Unwieldy") || weapon.Traits.includes("Pair") || weapon.Traits.includes("Difficult to use")) {
            return true;
        }
        if (weaponOnWarrior.Quantity > 1) {
            return true;
        }
        if (weapon.EquipmentType === "Wargear") {
            return true;
        }

    }
    if (weapon.EquipmentType === "Melee" && numberOfWeaponsOfSameType > 2) {
        return true;
    }
    if (weapon.EquipmentType === "Ranged" && numberOfWeaponsOfSameType > 1) {
        return true;
    }
    return false;
};

export function isTradingPostItem(input: IDatabaseEquipment | IDatabaseTradingPostItem): input is IDatabaseTradingPostItem {
    return (input as IDatabaseTradingPostItem).EquipmentRarity !== undefined;
}

export function isTradingPostItemArray(input: (IDatabaseEquipment | IDatabaseTradingPostItem)[]): input is IDatabaseTradingPostItem[] {
    return (input as IDatabaseTradingPostItem[]).every((item) => item.EquipmentRarity !== undefined);
}

export function isDatabaseEquipmentArray(input: (IDatabaseEquipment | IDatabaseTradingPostItem)[]): input is IDatabaseEquipment[] {
    return (input as IDatabaseEquipment[]).every((item) => item.ListId !== undefined);
}

const mapHouseRules = (settings: ISettings, equipment: (IDatabaseEquipment | IDatabaseTradingPostItem)[]): (IDatabaseEquipment | IDatabaseTradingPostItem)[] => {
    const cheapArmourFlag = settings.houseRules.find((rule) => rule === "Cheap Armour");
        const hammerNervFlag = settings.houseRules.find((rule) => rule === "Hammer Nerv");
        const slingNervFlag = settings.houseRules.find((rule) => rule === "Sling Nerv");
        const bucklerBuffFlag = settings.houseRules.find((rule) => rule === "Buckler Buff");
        const shieldBuffFlag = settings.houseRules.find((rule) => rule === "Shield Buff");
        const crossbowNerv = settings.houseRules.find((rule) => rule === "Unhandy Crossbows");
        const handgunBuff = settings.houseRules.find((rule) => rule === "Deadly Handguns");
        const helmetChange = settings.houseRules.find((rule) => rule === "Revised Helmets");
        const bowBuff = settings.houseRules.find((rule) => rule === "Elevate Short Bows");
        const cheapGuns = settings.houseRules.find((rule) => rule === "Cheap Guns");
        const spearBuff = settings.houseRules.find((rule) => rule === "Spear fix");
        return equipment.map((equi) => {
            if (cheapArmourFlag && (equi.EquipmentName === "Light Armour" || equi.EquipmentName === "Heavy Armour" || equi.EquipmentName === "Gromril Armour" || equi.EquipmentName === "Ithilmar Armour" || equi.EquipmentName === "Chaos Armour")) {
                equi.Price = Math.max(1, Math.floor(equi.Price / 2));
            }
            if (hammerNervFlag && equi.Price === 3 && equi.EquipmentName === "Hammer" || equi.EquipmentName === "Mace" || equi.EquipmentName === "Club" || equi.EquipmentName === "Staff") {
                equi.Price = 5;
            }
            if (slingNervFlag && equi.EquipmentName === "Sling") {
                equi.Strength = "2";
                equi.Distance = 16;
            }
            if (bucklerBuffFlag && equi.EquipmentName === "Buckler") {
                equi.Traits = "Combat Buckler;Parry";
            }
            if (shieldBuffFlag && equi.EquipmentName === "Shield") {
                equi.Traits = "Combat Shield";
            }
            if (crossbowNerv && equi.EquipmentName === "Crossbow") {
                equi.Traits = "Move or fire;Unhandy";
            }
            if (handgunBuff && equi.EquipmentName === "Handgun") {
                equi.Traits = "Prepare Shot;Cutting edge;Lead Poisoning";
            }
            if (helmetChange && equi.EquipmentName === "Helmet") {
                equi.Traits = "Durable";
            }
            if (bowBuff && equi.EquipmentName === "Short Bow") {
                equi.Distance = 20;
            }
            if (cheapGuns && equi.EquipmentSubType === "Blackpowder") {
                equi.Price = Math.ceil(equi.Price / 3 * 2);
            }
            if (spearBuff && equi.EquipmentName === "Spear") {
                equi.Traits = "Unwieldy;Lightning Reflexes";
            }
            return equi;
        });
    };

export const applyHouseRules = (settings: ISettings, equipment: IDatabaseEquipment[] ): ICombinedEquipment[] => {
    let preMappedEquipment:IDatabaseEquipment [] = [];
    if (settings.houseRules) {
        const houseRuledItems = mapHouseRules(settings, equipment);
        if (isDatabaseEquipmentArray(houseRuledItems)) {
            preMappedEquipment = houseRuledItems;
        }
        const changedEquiListsWithHammers = new Set<string>();
        preMappedEquipment.forEach((equi) => { equi.EquipmentName === "Hammer" || equi.EquipmentName === "Mace" || equi.EquipmentName === "Club" || equi.EquipmentName === "Staff" ? changedEquiListsWithHammers.add(equi.ListId) : null; });
        const hammerNervFlag = settings.houseRules.find((rule) => rule === "Hammer Nerv");
        if (hammerNervFlag) {
            changedEquiListsWithHammers.forEach((listId) => {
                preMappedEquipment.push({
                    ListId: listId,
                    EquipmentType: "Melee",
                    EquipmentSubType: "Bludgeoning Weapon",
                    EquipmentName: "Baton",
                    Price: 3,
                    Upgrade: false,
                    ArmourValue: 0,
                    Distance: 0,
                    Strength: "as User",
                    Traits: "",
                    Amount: 1,
                    Optional: 0
                });
            });
        }
    } else {
        preMappedEquipment = equipment;
    }
    return preMappedEquipment.map((equi) => ({ ...equi, Quantity: equi.Amount || 1, Price: equi.Price, ListId: isTradingPostItem(equi) ? "" : equi.ListId, Optional: equi.Optional || 0 }));
};

export const applyHouseRulesToTradingPost = (settings: ISettings, equipment: IDatabaseTradingPostItem[]): IDatabaseTradingPostItem[] => {
    let preMappedEquipment: IDatabaseTradingPostItem[] = [];
    if (settings.houseRules) {
        const houseRuledItems = mapHouseRules(settings, equipment);
        if (isTradingPostItemArray(houseRuledItems)) {
            preMappedEquipment = houseRuledItems;
        }
        const hammerNervFlag = settings.houseRules.find((rule) => rule === "Hammer Nerv");
        if (hammerNervFlag) {
            preMappedEquipment.push({
                EquipmentType: "Melee",
                EquipmentSubType: "Bludgeoning Weapon",
                EquipmentName: "Baton",
                Price: 3,
                Upgrade: false,
                ArmourValue: 0,
                Distance: 0,
                Strength: "as User",
                Traits: "",
                EquipmentRarity: "Common",
                WarbandIdExclusions: "",
                WarbandIdRestriction: "",
                HeroesOnly: `${HeroType.false}`
            });
        }
        return preMappedEquipment;
    } else {
        return equipment;
    }
};
