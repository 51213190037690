import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { loadWarrior } from "../redux/slices";
import { initialWarrior } from "../types/warrior";
import { useAuth0 } from "@auth0/auth0-react";
import { DialogDeleteFooter } from "../components/Footer";
import { WarriorHeaderSection } from "../components/warrior/WarriorHeader";
import { WarriorRules } from "../components/warrior/WarriorRules";
import { WarriorStats } from "../components/warrior/WarriorStats";
import { deleteWarrior } from "../redux/slices/warbandSlice";

export const DeleteWarriorPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [navigate, warband]);
    const warrior = useAppSelector((state) => state.tempwarrior);
    const deleteWarriorSaga = () => {
        dispatch(deleteWarrior(warrior));
        dispatch(loadWarrior(initialWarrior));
        navigate("/overview");
    };
    return <>
        <div>
            <h2>Delete Warrior</h2>
            <div className="section-container">

                <div className="warrior-card">
                    <WarriorHeaderSection givenWarrior={warrior} />
                    <WarriorStats givenWarrior={warrior} />
                    <WarriorRules givenWarrior={warrior} />
                </div>
                <div style={{textWrap: "balance", paddingBottom: "1em"}}>{`Are you sure, you want to delete ${warrior.Name} from your warband roster?`}</div>
                <div >This action cannot be undone!</div>
            </div>
        </div>
        <DialogDeleteFooter submitAction={deleteWarriorSaga} isEnabled={true} />
    </>;
};
