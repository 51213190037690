import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { getWarbandRating, getRoutLimit, getWarbandHeadcount } from "../../utilities/warbandProvider";

export const WarbandKPIs = () => {
    const warband = useAppSelector((state) => state.warband);
    const navigate = useNavigate();
    const [warbandFullName, setWarbandFullName] = useState<string>("");
    useEffect(() => {
        async function fetchWarbands() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setWarbandFullName(DatabaseProviderInstance.getWarbandHumanReadableType(warband.faction));
        }
        fetchWarbands();
    }, []);
    const MyDiv = ({ title, text }: { title: string, text: string }) => <div className="kpi-entry">
        <div style={{ fontSize: "0.75em", fontWeight: "bold" }}>{title}</div>
        <div >{text}</div>
    </div>;
    return <div className="section-container" style={{ position: "relative" }} onClick={() => { navigate("/edit-warband"); }} >
        <div className="warband-kpi">
            <MyDiv text={warbandFullName} title={"Warband Type"} />
            <MyDiv text={`${getWarbandRating(warband.warriors)}`} title={"Warband Rating"} />
        </div>
        <div className="warband-kpi">
            <MyDiv text={`${getWarbandHeadcount(warband.warriors)} / ${warband.limit}`} title={"Figher Limit"} />
            <MyDiv text={`${getRoutLimit(warband.warriors)}`} title={"Rout Limit"} />
        </div>
        <div className="warband-kpi">
            <MyDiv text={`${warband.cash} gc`} title={"Warchest"} />
            <MyDiv text={`${warband.treasure} pcs`} title={"Treasures"} />
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", maxWidth: "calc(var(--ifm-container-width) + 1em + 2px)" }} className="">
            <div style={{ width: "100%" }} className="kpi-entry">
                <div style={{ width: "100%", fontSize: "0.75em", fontWeight: "bold" }}>{"Warband Stash"}</div>
                <div style={{ width: "100%" }}>{warband.stash.length > 0 ? warband.stash.map((it) => `${it.Quantity}x ${it.EquipmentName}`).join(", ") : "Your stash is empty."}</div>
            </div>
        </div>
        {warband.warbandRules.length > 0 ? <div style={{ width: "100%", display: "flex", justifyContent: "center", maxWidth: "calc(var(--ifm-container-width) + 1em + 2px)" }} className="">
            <div style={{ width: "100%" }} className="kpi-entry">
                <div style={{ width: "100%", fontSize: "0.75em" }}>{"Warband Rules"}</div>
                <div style={{ width: "100%", fontWeight: "bold" }}>{warband.warbandRules.map((el) => <>{el.RuleName}<br /></>)}</div>
            </div>
        </div> : null}
        <FontAwesomeIcon icon={faEllipsis} style={{ position: "absolute", bottom: "1em", right: "1.5em", height: "1.2em" }} />
    </div>;
};
export const WarbandPdfKPIs = () => {
    const warband = useAppSelector((state) => state.warband);
    const [warbandFullName, setWarbandFullName] = useState<string>("");
    useEffect(() => {
        async function fetchWarbands() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setWarbandFullName(DatabaseProviderInstance.getWarbandHumanReadableType(warband.faction));
        }
        fetchWarbands();
    }, []);
    const warriorTypes = warband.warriors.reduce((acc, el) => {
        if (acc.has(el.WarriorType)) {
            acc.set(el.WarriorType, (acc.get(el.WarriorType)?.valueOf() || 0) + el.HeadCount);
        } else {
            acc.set(el.WarriorType, el.HeadCount);
        }
        return acc;
    }, new Map<string, number>()).entries();
    const warriorList: JSX.Element[] = [];
    for (const item of warriorTypes) {
        warriorList.push(<div style={{ fontSize: "1.4em", padding: "0.5em" }}>{`${item[1]}x ${item[0]}`}</div>);
    }
    const MyDiv = ({ title, text }: { title: string, text: string }) => <div style={{ width: "100%" }} className="kpi-entry">
        <div style={{ fontSize: "1.7em", fontWeight: "bold" }}>{title}</div>
        <div style={{ fontSize: "1.5em" }}>{text}</div>
    </div>;
    return <>
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "70%" }}>
                <div style={{ paddingBottom: "0.7em" }} className="warband-kpi">
                    <MyDiv text={warbandFullName} title={"Warband Type"} />
                    <MyDiv text={`${getWarbandRating(warband.warriors)}`} title={"Warband Rating"} />
                </div>
                <div style={{ paddingBottom: "0.7em" }} className="warband-kpi">
                    <MyDiv text={`${warband.warriors.reduce((acc, warr) => {
                        acc += parseInt(warr.TotalCost as any, 10) * warr.HeadCount;
                        console.log(acc);
                        return acc;
                        }, 0)} gold coins`} title={"Warband total cost"} />
                    <MyDiv text={`${warband.cash} gold coins`} title={"Warchest"} />
                </div>
                <div style={{ paddingBottom: "0.7em" }} className="warband-kpi">
                    <MyDiv text={`${getWarbandHeadcount(warband.warriors)} / ${warband.limit}`} title={"Figher Limit"} />
                    <MyDiv text={`${getRoutLimit(warband.warriors)}`} title={"Rout Limit"} />
                </div>
                <div style={{ paddingBottom: "0.7em" }} className="warband-kpi">
                    <MyDiv text={`${warband.treasure} pieces`} title={"Treasures"} />
                    <MyDiv text={warband.stash.length > 0 ? warband.stash.map((it) => `${it.Quantity}x ${it.EquipmentName}`).join(", ") : "Your stash is empty."} title="Warband Stash" />
                </div>
            </div>
            <div style={{ width: "30%" }} className="kpi-entry">
                <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>{"Fighter List"}</div>
                {warriorList}
            </div>
        </div>
        <div style={{ width: "100%" }} className="kpi-entry">
            <div style={{ fontSize: "1.5em", fontWeight: "bold", paddingBottom: "0.5em", paddingTop: "1em" }}>{"Warband Rules"}</div>
            {warband.warbandRules.length > 0 ? warband.warbandRules.map((it) => <><div style={{ fontSize: "1.3em", fontWeight: "bold" }}>{it.RuleName}</div><div style={{ fontSize: "1.2em", padding: "0.5em" }}>{it.Text}</div></>) : "Your warband has no special rules"}
        </div>
    </>;
};