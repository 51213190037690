import { setWarriorName } from "../../redux/slices";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IWarrior } from "../../types/warrior";
import { TextInputControl } from "../Input";

const nameRegex = new RegExp("[a-zA-Z0-9]{3,}");

export const WarriorNameInput = ({ givenWarrior }: { givenWarrior?: IWarrior }) => {
    const dispatch = useAppDispatch();
    const warrior = givenWarrior || useAppSelector((state) => state.tempwarrior);
    const commandHandler = (name: string) => {
        dispatch(setWarriorName(name));
    };
    return <TextInputControl
        label="Warrior name (min. 3 characters)"
        dispatchCommand={commandHandler}
        regex={new RegExp(nameRegex)}
        prefilled={warrior.Name}
    />;
};