import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { addWarrior, loadWarrior, resetLog, setHeadCount, updateWarrior } from "../redux/slices";
import { HeroType, initialWarrior, IWarrior } from "../types/warrior";
import { useAuth0 } from "@auth0/auth0-react";
import { WarriorStats } from "../components/warrior/WarriorStats";
import { WarriorHeaderSection } from "../components/warrior/WarriorHeader";
import { WarriorRulesSelection } from "../components/dialogs/WarriorRulesSelection";
import { DialogPageFooter } from "../components/Footer";
import { SkillListsSelection } from "../components/dialogs/WarriorSkillListsSelection";
import { StatsMaintenanceSection } from "../components/dialogs/WarriorStatsMaintain";
import { WarriorNameInput } from "../components/warrior/WarriorName";
import { setHero } from "../redux/slices/warriorSlice";
import { WarriorRules } from "../components/warrior/WarriorRules";
import { deleteWarrior } from "../redux/slices/warbandSlice";

export const PromoteWarriorPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const warrior = useAppSelector((state) => state.tempwarrior);
    const originalWarrior = useRef(warrior);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
        if (!warrior) {
            navigate("/overview");
        }
        dispatch(setHeadCount(1));
        dispatch(setHero(HeroType.true));
    }, [navigate, warrior]);

    const addWarriorSaga = () => {
        dispatch(addWarrior(warrior));
        dispatch(resetLog());
        if (originalWarrior.current.HeadCount > 1) {
            dispatch(updateWarrior({...originalWarrior.current, HeadCount: originalWarrior.current.HeadCount - 1}));
        } else {
            dispatch(deleteWarrior(originalWarrior.current));
        }
        dispatch(loadWarrior(initialWarrior));
        navigate("/overview");
    };
    return <>
        <div>
            <h2>Promote Warrior</h2>
            <div className="section-container">
                <WarriorSheet givenWarrior={warrior} />
            </div>
        </div>
        <DialogPageFooter submitAction={addWarriorSaga} isEnabled={warrior.SkillLists?.length === 2 && (originalWarrior.current.HeadCount === 1 || warrior.Name !== originalWarrior.current.Name)} />
    </>;
};

export const WarriorSheet = ({ givenWarrior }: { givenWarrior: IWarrior }) => {
    return <>
        <WarriorNameInput givenWarrior={givenWarrior}/>

        <div style={{textWrap: "balance"}}>You may choose two skill lists available to Heroes in your warband. These are the skill types your new Hero can choose from when he gains new skills.</div>
        <SkillListsSelection />

        {givenWarrior.SkillLists?.length === 2 ? <WarriorRulesSelection givenWarrior={givenWarrior} /> : null }
        <StatsMaintenanceSection/>
        <div className="warrior-card">
            <WarriorHeaderSection givenWarrior={givenWarrior} />
            <WarriorStats givenWarrior={givenWarrior} />
            <WarriorRules givenWarrior={givenWarrior} />
        </div>
    </>;
};

