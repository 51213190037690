import { useState } from "react";
import { useAppSelector } from "../../redux/store";
import { ICombinedEquipment } from "../../types/database";
import { GenericEquipment, SubTypeSelection } from "../Equipment";
import { EquipmentModalFooter } from "../Footer";
import { NumberInputControl } from "../Input";

export const ModalEquipment = ({ item, setShowMyPriceModal }: { item: ICombinedEquipment; setShowMyPriceModal(ins: boolean): void }) => {
    const warrior = useAppSelector((state) => state.tempwarrior);
    const [myPrice, setMyPrice] = useState<number>();
    const [subType, setSubType] = useState<string>("");
    return <div className="modal">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="header-1">Modify equipment</div>
                <GenericEquipment equipment={{ ...item, EquipmentSubType: item.EquipmentSubType || subType }} />
                <NumberInputControl label={"Set the actual price for this item"} dispatchCommand={setMyPrice} prefilled={`${parseInt(item.Price.toString(), 10)}`} />
                {item.EquipmentName === "Double-handed Weapon" ? <>
                    <div className="warrior-name-container">Select a subtype for this weapon:</div>
                    <SubTypeSelection activeSubType={subType} callback={(input: string) => setSubType(input)} />
                </> : null}
                <EquipmentModalFooter
                    equi={{ ...item, Price: myPrice !== undefined ? myPrice : item.Price, EquipmentSubType: subType || item.EquipmentSubType }}
                    warrior={warrior}
                    closeModalFn={() => setShowMyPriceModal(false)} />
            </div>
        </div>
    </div>;
};