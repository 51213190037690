import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWarband } from "../../types/warband";
import { IWarrior } from "../../types/warrior";
import { ICombinedEquipment } from "../../types/database";
import { ILogEntry } from "./logSlice";

export const initialWarband: IWarband = {
    name: "",
    faction: "",
    cash: 0,
    stash: [],
    warriors: [],
    treasure: 0,
    campaignLink: "",
    limit: 0,
    id: window.self.crypto.randomUUID(),
    log: [],
    warbandRules: []
};

const warbandSlice = createSlice({
    name: "warrior",
    initialState: initialWarband,
    reducers: {
        setWarbandName(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },
        setWarbandFaction(state, action: PayloadAction<string>) {
            state.faction = action.payload;
        },
        setCampaignUrl(state, action: PayloadAction<string>) {
            state.campaignLink = action.payload;
        },
        loadWarband(_state, action: PayloadAction<IWarband>) {
            return action.payload;
        },
        addWarrior(state, action: PayloadAction<IWarrior>) {
            const warrior = action.payload;
            state.warriors.push({ ...warrior, Position: state.warriors.length || 0 });
        },
        deleteWarrior(state, action: PayloadAction<IWarrior>) {
            const idx = state.warriors?.findIndex((item) => item.Id === action.payload.Id);
            if (idx !== undefined) {
                state.warriors?.splice(idx, 1);
            }
        },
        updateWarrior(state, action: PayloadAction<IWarrior>) {
            const mappedState = state.warriors.map((item) => {
                if (item.Id === action.payload.Id) {
                    return action.payload;
                }
                return item;
            });
            state.warriors = mappedState;
        },
        changeFunds(state, action: PayloadAction<number>) {
            state.cash += action.payload;
        },
        changeTreasures(state, action: PayloadAction<number>) {
            state.treasure += action.payload;
        },
        addToStash(state, action: PayloadAction<ICombinedEquipment>) {
            const weapon = state.stash?.find((item) => item.EquipmentName === action.payload.EquipmentName && item.EquipmentType === action.payload.EquipmentType && item.EquipmentSubType === action.payload.EquipmentSubType);
            if (weapon) {
                weapon.Quantity++;
            } else {
                state.stash?.push(action.payload);
            }
        },
        removeFromStash(state, action: PayloadAction<ICombinedEquipment>) {
            const weapon = state.stash?.find((item) => item.EquipmentName === action.payload.EquipmentName && item.EquipmentType === action.payload.EquipmentType && item.EquipmentSubType === action.payload.EquipmentSubType);
            if (weapon) {
                            if (weapon.Quantity > 1) {
                weapon.Quantity--;
            } else {
                state.stash = state.stash?.filter((it) => it.EquipmentName !== action.payload.EquipmentName);
            }
            }
        },
        addWarbandLog(state, action: PayloadAction<ILogEntry[]>) {
            state.log.push(...action.payload);
        }
    },
});

export const { deleteWarrior, changeTreasures, addWarrior, addWarbandLog, loadWarband, changeFunds, setCampaignUrl, setWarbandFaction, setWarbandName, updateWarrior, addToStash, removeFromStash } = warbandSlice.actions;

export default warbandSlice.reducer;