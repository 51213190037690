import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { useAppSelector } from "../redux/store";
import { OverviewWarriorSheets } from "../components/warrior/WarriorSheet";
import { WarbandKPIs } from "../components/warband/WarbandKpis";
import { WarbandControls } from "../components/warband/WarbandControls";

export const WarbandOverviewPage = () => {
    const navigate = useNavigate();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!warband.name) {
            navigate("/");
        }
    }, [navigate, warband]);
    return <React.Fragment>
        <h2>{warband.name}</h2>
        <WarbandKPIs />
        <WarbandControls />
        <OverviewWarriorSheets />
        <Footer />
    </React.Fragment>;
};
