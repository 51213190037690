import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { WarriorSheet } from "../components/warrior/WarriorSheet";
import { HiredSwordSelection } from "../components/Dropdown";
import { WarriorHeadCountSelection } from "../components/warrior/WarriorHeadcount";
import { WarriorNameInput } from "../components/warrior/WarriorName";
import { SpellSelection } from "../components/dialogs/WarriorSpellSelection";
import { DialogPageFooter } from "../components/Footer";
import { buyWarriorSaga } from "../redux/sagas/warriorSaga";
import { PurchaseWeapons } from "../components/dialogs/WarriorEquipmentMaintenance";
import { DataBaseProvider } from "../utilities/DatabaseProvider";
import { ICombinedEquipment } from "../types/database";
export const AddHiredSwordPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const warband = useAppSelector((state) => state.warband);
    const warrior = useAppSelector((state) => state.tempwarrior);
    const [equipmentOptions, setEquipmentOptions] = useState<ICombinedEquipment[]>([]);
    const [selectedEquipment, setSelectedEquipment] = useState<ICombinedEquipment>();
    useEffect(() => {
        if (!warband.name) {
            navigate("/");
        }
        async function fetchEquipment() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            const equipment = DatabaseProviderInstance.getEquipment(warrior.EquipmentList);
            setEquipmentOptions(equipment);
        }
        fetchEquipment();
    }, [navigate, warband, warrior.EquipmentList]);

    const delta = useAppSelector((state) => state.delta);
    const templog = useAppSelector((state) => state.templog);
    const deltaFunds = delta.reduce((acc, curr) => acc + (curr.value || 0), 0);
    const enabled = !!warrior.Name &&
        warrior.Name.length >= 3 &&
        !!(!warrior.Rules?.find((rule) => rule.RuleName === "Wizard" || rule.RuleName === "Priest") || warrior.Spells?.length) &&
        deltaFunds <= warband.cash;
    return <>
        <div>
            <h2>Add Hired Sword</h2>

            <div className="section-container">
                <WarriorNameInput />
                <HiredSwordSelection />
                {warrior.WarriorType ?
                    <>
                        {warrior.Hero ? null : <WarriorHeadCountSelection />}
                        <WarriorSheet warrior={{...warrior, Equipment: warrior.FixedEquipment ? warrior.Equipment : selectedEquipment ? [selectedEquipment] : undefined}} readOnly={true}/>
                        {warrior.FixedEquipment ? null : <>
                            <div className="header-1">Select equipment</div>
                            <div className="warrior-type">Click on the equipment you want your hired sword to have</div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
                            {equipmentOptions.map((equi) => <div onClick={() => setSelectedEquipment(equi)} className={selectedEquipment?.EquipmentName === equi.EquipmentName ? "focused" : ""} style={{padding: "1em", width: "40%", textAlign: "center"}} key={equi.EquipmentName}>{`${equi.Quantity > 1 ? `${equi.Quantity}x`: ""} ${equi.EquipmentName}`}</div>)}
                            </div>
                                </>}
                        {warrior.Rules?.find((rule) => rule.RuleName === "Wizard" || rule.RuleName === "Priest") ? <SpellSelection editMode={false}/> : null}
                    </> :
                    null
                }
            </div>
        </div>
        <DialogPageFooter submitAction={() => {buyWarriorSaga(templog, {...warrior, Equipment: warrior.FixedEquipment ? warrior.Equipment : selectedEquipment ? [selectedEquipment] : undefined}, deltaFunds, dispatch); navigate("/overview");}} isEnabled={enabled} />
    </>;
};


export const SelectWeapons = () => {
    const warrior = useAppSelector((state) => state.tempwarrior);

    return
};