import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../../redux/store";
import { ICombinedEquipment, IDatabaseTradingPostItem } from "../../types/database";

export const TradingPostEquipmentLine = ({ fullItem, setItem, showModal }: { fullItem: IDatabaseTradingPostItem; setItem: (input: IDatabaseTradingPostItem) => void; showModal: (show: true) => void }) => {
    const clickHandler = () => {
        setItem(fullItem);
        showModal(true);
    };
    return <div key="dummy-key" className="equipment-selection-item-container" style={{justifyContent: "space-between", padding: "0.7em 1em", borderBottom: "1px solid lightgrey", textWrap: "balance"}} onClick={clickHandler}>
        <div style={{width:"40%", textWrap: "balance"}}>{fullItem.EquipmentName}</div>
        <div style={{ maxWidth: "20%", textWrap: "balance",textAlign: "center"}}>{fullItem.Price}gc</div>
        <div style={{ textWrap: "nowrap"}}>[{fullItem.EquipmentRarity === "Common" || fullItem.EquipmentRarity === "Strength Test" ? `${fullItem.EquipmentRarity}`: `Rare ${fullItem.EquipmentRarity}`}]</div>
        <FontAwesomeIcon icon={faEllipsis} />
    </div>;
};


export const WarbandEquipmentLine = ({ fullItem, setItem, showModal }: { fullItem: ICombinedEquipment; setItem: (input: ICombinedEquipment) => void; showModal: (show: true) => void }) => {
    const warrior = useAppSelector((state) => state.tempwarrior);
    const weaponOnWarrior = warrior.Equipment?.find((equipment) => fullItem.EquipmentName === equipment.EquipmentName);
    const clickHandler = () => {
        setItem(weaponOnWarrior || fullItem);
        showModal(true);
    };

    const quantity = weaponOnWarrior?.Quantity || 0;
    return <div key="dummy-key" className="equipment-selection-item-container" style={{justifyContent: "space-between", padding: "0.7em 1em", borderBottom: "1px solid lightgrey", textWrap: "balance", background: quantity ? "color-mix(in srgb, var(--color-emphasis) 30%, white)" : ""}} onClick={clickHandler}>
        <div style={{width:"40%", textWrap: "balance"}}>{fullItem.EquipmentName} {quantity ? `${quantity}x` : ""}</div>
        <div style={{ maxWidth: "20%", textWrap: "balance",textAlign: "center"}}>{fullItem.Price}gc</div>
        <FontAwesomeIcon icon={faEllipsis} />
    </div>;
};

export const StashEquipmentLine = ({ fullItem, setCurrentItem }: { fullItem: ICombinedEquipment; setCurrentItem: (item: ICombinedEquipment) => void }) => {
    const clickHandler = () => {
        setCurrentItem(fullItem);
    };
    return <div key="dummy-key" className="equipment-selection-item-container" style={{justifyContent: "space-between", padding: "0.7em 1em", borderBottom: "1px solid lightgrey", textWrap: "balance"}} onClick={clickHandler}>
        <div style={{width:"40%", textWrap: "balance"}}>{fullItem.EquipmentName} {fullItem.Quantity ? `${fullItem.Quantity}x` : ""}</div>
        <div style={{ maxWidth: "20%", textWrap: "balance",textAlign: "center"}}>{fullItem.Price}gc</div>
        <FontAwesomeIcon icon={faEllipsis} />
    </div>;
};