import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../../redux/store";
import { IWarrior } from "../../types/warrior";

export const WarriorHeaderSection = ({ givenWarrior }: { givenWarrior?: IWarrior }) => {
    const warrior = givenWarrior || useAppSelector((state) => state.tempwarrior);
    return <div className="warrior-name-container">
        <div className="warrior-name">
            <div>{warrior.Name}</div>
            <div className="warrior-type">{warrior.HeadCount > 1 ? `${warrior.HeadCount}x ` : null}{warrior.WarriorType}</div>
        </div>
        <div className="warrior-header-icons-container">
            <div className="warrior-cost">
                <div>
                <div style={{ padding: "0em 0.2em 0em 0.5em", textAlign: "center" }}>{warrior.HeadCount * warrior.TotalCost}</div>
                <div style={{ padding: "0em 0.2em 0em 0.5em", fontSize: "0.8em", fontWeight: "normal" }}>{warrior.Upkeep ? ` ${warrior.Upkeep} upkeep` : ""}</div>
                </div>
                <FontAwesomeIcon icon={faCoins} style={{ height: "0.7em" }} />
            </div>
        </div>
    </div>;
};