import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faSquare, faSquareCheck, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { increaseExperienceSaga } from "../../redux/sagas/warriorSaga";
import { increaseXP } from "../../redux/slices";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { HeroType, IWarrior } from "../../types/warrior";
import { isLevelUp } from "../../utilities/WarriorHelper";
import { ButtonControl } from "../Button";

export const WarriorExperience = ({ givenWarrior }: { givenWarrior?: IWarrior }) => {
    const warrior = givenWarrior || useAppSelector((state) => state.tempwarrior);
    const dispatch = useAppDispatch();
    const [showHint, setShowHint] = useState<boolean>(false);
    const increaseXp = (reason: string) => {
        increaseExperienceSaga(reason, warrior, dispatch);
        if (isLevelUp(warrior.Experience+1)) {
            setShowHint(true);
        }
    };
    return <>
        <h4>Maintain Experience</h4>
        <div key={""} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {Array.from(Array(warrior.Hero ? 41 : 21)).map((_e, i) => {
                const xp = i + 1;
                const levelUp = isLevelUp(xp);
                return <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end", color: levelUp ? "black" : "" }} key={""}>
                    <div style={{ height: "1em", fontWeight: "bold" }}>{xp%5===0 ? xp : ""}</div>
                    <FontAwesomeIcon style={{padding: "0.3em", height: levelUp ? "1.15em" : "1em"}} icon={(xp <= warrior.Experience ? faSquareCheck : faSquare) as IconDefinition} />
                </div>
            })}
        </div>
        <div key="dummy-key" style={{display: "flex", alignItems: "center",justifyContent: "flex-start", flexDirection:"column" }} >
                    <div key="dummy-key" className="skill-selection-item">Gain experience for</div>
                        <ButtonControl label={"Winning Leader"} command={() => increaseXp("winning the last battle.")} enabled={!!warrior.Rules?.find((rule) => rule.RuleName === "Leader")}/>
                        <ButtonControl label={"Kill opponent"} command={() => increaseXp("putting an enemy warrior out of action in the battle.")} enabled={warrior.Hero !== HeroType.false}/>
                        <ButtonControl label={"Took part"} command={() => increaseXp("taking part in the battle.")} enabled={true}/>
                        <ButtonControl label={"Scenario specific"} command={() => increaseXp("completing a scenario specific task in the last battle.")} enabled={true}/>

                </div>
                {showHint ? <div style= {{paddingTop: "1em", borderTop: "2px solid var(--color-light)"}}>{`Congratulations! ${warrior.Name} leveled up. Don't forget to maintain your advances`}</div> : null}
    </>;
};