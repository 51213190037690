import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";
import { IDatabaseInjury } from "../../types/database";
import { IWarrior, Stats } from "../../types/warrior";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";

export const WarriorStats = ({ givenWarrior }: { givenWarrior?: IWarrior }) => {
    const warrior = givenWarrior || useAppSelector((state) => state.tempwarrior);
    const [injuries, setInjuries] = useState<IDatabaseInjury[]>([]);
    useEffect(() => {
        async function fetchInjuries() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setInjuries(DatabaseProviderInstance.injuries);
        }
        fetchInjuries();
    }, []);
    const actualInjuries = warrior.Injuries?.reduce((acc, injury) => {
        const fullInjury = injuries.find((el) => el.Injury === injury);
        if (!fullInjury || !fullInjury.Characteristic) {
            return acc;
        }
        acc.push(fullInjury);
        return acc;
    }, [] as IDatabaseInjury[]) || [];

    return <table className="table stats-table">
        <thead>
            <tr>
                {Object.keys(Stats).map((stat) => <td key={stat}>{stat}</td>)}
                <td className="focused" style={{ fontWeight: "bold", borderBottom: "0.1em solid white" }}>XP</td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style={{color: actualInjuries.find((inj) => inj.Characteristic === "M") ? "var(--color-not-ok)" : ""}}>{actualInjuries.find((inj) => inj.Characteristic === "M") ? warrior.M - 1 : warrior.M}&quot;</td>
                <td>{warrior.WS}</td>
                <td style={{color: actualInjuries.find((inj) => inj.Characteristic === "BS") ? "var(--color-not-ok)" : ""}}>{actualInjuries.find((inj) => inj.Characteristic === "BS") ? warrior.BS - 1 : warrior.BS}</td>
                <td>{warrior.S}</td>
                <td style={{color: actualInjuries.find((inj) => inj.Characteristic === "T") ? "var(--color-not-ok)" : ""}}>{actualInjuries.find((inj) => inj.Characteristic === "T") ? warrior.T - 1 : warrior.T}</td>
                <td>{warrior.W}</td>
                <td style={{color: actualInjuries.find((inj) => inj.Characteristic === "I") ? "var(--color-not-ok)" : ""}}>{actualInjuries.find((inj) => inj.Characteristic === "I") ? warrior.I - 1 : warrior.I}</td>
                <td>{warrior.A}</td>
                <td>{warrior.LD}</td>
                <td className="focused" style={{ fontWeight: "bold", borderBottom: "0.1em solid white" }}>{warrior.Experience}</td>
            </tr>
        </tbody>
    </table>;
};