import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare, faCircleUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { addLog, removeLogByCommand, setSpell } from "../../redux/slices";
import { addSpell, upgradeSpell } from "../../redux/slices/warriorSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IDatabaseSpell, ISpellcaster } from "../../types/database";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { CollapsibleSection } from "./WarriorEquipmentMaintenance";

export const SpellSelection = ({editMode}:{editMode: boolean}) => {
    const dispatch = useAppDispatch();
    const warrior = useAppSelector((state) => state.tempwarrior);
    const maxSpells = (warrior.WarriorType === "Wolf Priest of Ulric" || warrior.WarriorType === "Warlock") ? 2 : 1;
    const upgradeThisSpell = (spell: string) => {
        const foundSpell = warrior.Spells?.find((actualSpell) => actualSpell.SpellName === spell);
        if (foundSpell) {
            const upgradedSpell = { ...foundSpell, Difficulty: foundSpell.Difficulty - 1 };
            dispatch(upgradeSpell(upgradedSpell));
        }
    };
    const onChangeHandler = (input: string) => {
        dispatch(removeLogByCommand({ command: "Add spells", value: "" }));
        if (editMode) {
            if (selectedSpells.includes(input)) {
                const filteredSpells = selectedSpells.filter((spell) => spell !== input);
                setSelectedSpells(filteredSpells);
                const fullFilteredSpells = filteredSpells.reduce((acc: IDatabaseSpell[], item) => {
                    const foundSpell = spellCaster?.SpellOptions.find((spell) => spell.SpellName === item);
                    if (foundSpell) {
                        acc.push(foundSpell);
                    }
                    return acc;
                }, []);
                fullFilteredSpells.forEach((spell) => dispatch(addLog({ command: "Add spells", value: spell.SpellName })));
                dispatch(setSpell(fullFilteredSpells));
                return;
            }
            const foundSpell = spellCaster?.SpellOptions.find((spell) => spell.SpellName === input);
            if (foundSpell) {
                setSelectedSpells([...selectedSpells, input]);
                dispatch(addLog({ command: "Add spell", value: input }));
                dispatch(addSpell(foundSpell));
                
            }
            return;
        }
        if (maxSpells === 2 && !editMode) {
            if (selectedSpells) {
                if (selectedSpells.includes(input)) {
                    const filteredSpells = selectedSpells.filter((spell) => spell !== input);
                    setSelectedSpells(filteredSpells);
                    const fullFilteredSpells = filteredSpells.reduce((acc: IDatabaseSpell[], item) => {
                        const foundSpell = spellCaster?.SpellOptions.find((spell) => spell.SpellName === item);
                        if (foundSpell) {
                            acc.push(foundSpell);
                        }
                        return acc;
                    }, []);
                    fullFilteredSpells.forEach((spell) => dispatch(addLog({ command: "Add spells", value: spell.SpellName })));
                    dispatch(setSpell(fullFilteredSpells));
                    return;
                }
                const spells = selectedSpells;
                if (selectedSpells.length === 2) {
                    spells.shift();
                }
                const selected = spells ? [...spells, input] : [input];
                const actualSpells = selected.reduce((acc: IDatabaseSpell[], item) => {
                    const foundSpell = spellCaster?.SpellOptions.find((spell) => spell.SpellName === item);
                    if (foundSpell) {
                        acc.push(foundSpell);
                    }
                    return acc;
                }, []);
                setSelectedSpells(actualSpells.map((spell) => spell.SpellName));
                actualSpells.forEach((spell) => dispatch(addLog({ command: "Add spells", value: spell.SpellName })));
                dispatch(setSpell(actualSpells));
            } else {
                setSelectedSpells([input]);
                dispatch(addLog({ command: "Add spells", value: input }));
                dispatch(setSpell(spellCaster?.SpellOptions.filter((spell) => spell.SpellName === input) || []));
            }
        } else {
            setSelectedSpells([input]);
            dispatch(addLog({ command: "Add spells", value: input }));
            dispatch(setSpell(spellCaster?.SpellOptions.filter((spell) => spell.SpellName === input) || []));

        }
    };
    const [spellCaster, setSpellCaster] = useState<ISpellcaster>();
    useEffect(() => {
        async function fetchWizards() {
            if (warrior.WarriorType) {
                const DatabaseProviderInstance = await DataBaseProvider.getInstance();
                const spellCaster = await DatabaseProviderInstance.getSpellcaster(warrior.WarriorType);
                setSpellCaster(spellCaster);
                setShowSection([`Select ${spellCaster?.MagicType}`]);
            }
        }
        fetchWizards();
    }, []);
    const [selectedSpells, setSelectedSpells] = useState<string[]>(warrior.Spells?.map((spell) => spell.SpellName) || []);
    const sectionId = `Select ${spellCaster?.MagicType}`;
    const [showSection, setShowSection] = useState<string[]>([sectionId]);
    const toggleShowSection = (section: string) => {
        if (showSection.includes(section)) {
            setShowSection(showSection.filter((show) => show !== section));
        } else {
            setShowSection([...showSection, section]);
        }
    };
    return <>
        <CollapsibleSection
            key={sectionId}
            withoutHeader={true}
            sectionId={sectionId}
            sectionText={sectionId}
            sectionChildren={spellCaster?.SpellOptions.map((spell) => {
                const isActive = selectedSpells?.find((selected) => selected === spell.SpellName);
                return <div key="dummy-key" className="skill-selection-item-container" onClick={() => { onChangeHandler(spell.SpellName); }}>
                    <div key="dummy-key" className="skill-selection-item">{spell.SpellName}</div>
                    {isActive ?
                        <div>
                            {editMode ? <FontAwesomeIcon
                                icon={faCircleUp as IconProp}
                                className="skill-selection-icon"
                                onClick={() => { upgradeThisSpell(spell.SpellName); }} /> : null}
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="skill-selection-icon"
                                onClick={() => { onChangeHandler(spell.SpellName); }} />
                        </div>
                        :
                        <FontAwesomeIcon
                            icon={faSquare as IconProp}
                            className="skill-selection-icon"
                             />
                    }
                </div>;
            }) || []}
            showSection={showSection.includes(sectionId)}
            toggleFunction={() => toggleShowSection(sectionId)} />
    </>;
};