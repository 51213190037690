import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { WarriorHeadCountSelection } from "../components/warrior/WarriorHeadcount";
import { IWarrior } from "../types/warrior";
import { useAuth0 } from "@auth0/auth0-react";
import { WarriorStats } from "../components/warrior/WarriorStats";
import { WarriorHeaderSection } from "../components/warrior/WarriorHeader";
import { InjurySelection } from "../components/dialogs/WarriorInjurySelection";
import { DialogPageFooter } from "../components/Footer";
import { WarriorRules } from "../components/warrior/WarriorRules";
import { updateWarriorSaga } from "../redux/sagas/warriorSaga";

export const MaintaintWarriorInjuriesPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [navigate, warband]);
    const warrior = useAppSelector((state) => state.tempwarrior);
    const templog = useAppSelector((state) => state.templog);
    const changeWarrior = () => {
        updateWarriorSaga(templog, warrior, 0, dispatch);
        navigate("/overview");
    };
    return <>
        <div>
            <h2>Warrior Injuries</h2>
            <div className="section-container">
                {warrior.Hero ? null : <WarriorHeadCountSelection />}
                <RulesWarriorSheet warrior={warrior} />
            </div>
        </div>
        <DialogPageFooter submitAction={changeWarrior} isEnabled={true} />
    </>;
};

export const RulesWarriorSheet = ({ warrior }: { warrior: IWarrior }) => {
    return <>
        <div className="warrior-card">
            <WarriorHeaderSection givenWarrior={warrior} />
            <WarriorStats givenWarrior={warrior} />
            <WarriorRules givenWarrior={warrior} />
        </div>
        <InjurySelection />
    </>;
};

