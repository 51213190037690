import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { loadWarrior, resetDelta, changeFunds, updateWarrior, addWarbandLog, resetLog } from "../redux/slices";
import { initialWarrior } from "../types/warrior";
import { StatsMaintenanceSection } from "../components/dialogs/WarriorStatsMaintain";
import { WarriorNameInput } from "../components/warrior/WarriorName";
import { WarriorHeaderSection } from "../components/warrior/WarriorHeader";
import { WarriorStats } from "../components/warrior/WarriorStats";
import { DialogPageFooter } from "../components/Footer";
export const MaintainWarriorHeadPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!warband.name) {
            navigate("/");
        }
    }, [navigate, warband]);
    const warrior = useAppSelector((state) => state.tempwarrior);
    const templog = useAppSelector((state) => state.templog);
    const addWarriorSaga = () => {
        dispatch(addWarbandLog([...templog, { command: "Update warrior", value: `${warrior.Name} | ${warrior.HeadCount > 1 ? `${warrior.HeadCount}x ` : ""}${warrior.WarriorType}`, cost: warrior.Cost }]));
        dispatch(updateWarrior(warrior));
        dispatch(resetLog());
        dispatch(loadWarrior(initialWarrior));
        dispatch(resetDelta());
        navigate("/overview");
    };
    return <React.Fragment>
        <div>
            <h2>Modify Warrior</h2>
            <div className="section-container">
                <WarriorNameInput />
                <div className="warrior-card">
                    <WarriorHeaderSection/>
                    <WarriorStats/>
                </div>
                <StatsMaintenanceSection />
            </div>
        </div>
        <DialogPageFooter submitAction={addWarriorSaga} isEnabled={true} />
    </React.Fragment>;
};


