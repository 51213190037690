import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { addSkill } from "../../redux/slices";
import { removeSkill } from "../../redux/slices/warriorSlice";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { IDatabaseRule } from "../../types/database";
import { IWarrior } from "../../types/warrior";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { CollapsibleSection } from "./WarriorEquipmentMaintenance";

export const WarriorRulesSelection = ({ givenWarrior }: { givenWarrior?: IWarrior }) => {
    const warrior = givenWarrior || useAppSelector((state) => state.tempwarrior);
    const dispatch = useAppDispatch();
    const [skills, setSkills] = useState<IDatabaseRule[]>([]);
    const [activeSkills, setActiveSkills] = useState<IDatabaseRule[]>(warrior.Rules || []);
    const [showSection, setShowSection] = useState<string[]>([]);
    const toggleShowSection = (section: string) => {
        if (showSection.includes(section)) {
            setShowSection(showSection.filter((show) => show !== section));
        } else {
            setShowSection([...showSection, section]);
        }
    };

    useEffect(() => {
        async function fetchSkills() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            const allAvailableSkills = warrior.SkillLists?.reduce((acc, listId) => {
                const skills = DatabaseProviderInstance.getSkillOptions(listId);
                acc.push(...skills);
                return acc;
            }, [] as IDatabaseRule[]);
            setSkills(allAvailableSkills || []);
        }
        fetchSkills();
    }, [warrior]);
    return <>
        <h4>Select skills</h4>
        {warrior.SkillLists?.map((listId) => {
            const showThisSection = showSection.includes(listId);
            const listSkills = skills.filter((skill) => skill.ListId === listId);
            return <CollapsibleSection
                key={listId}
                withoutHeader={false}
                sectionId={listId}
                sectionText={listId}
                sectionChildren={listSkills.map((skill) => {
                    const isActive = activeSkills.find((activeSkill) => activeSkill.RuleName === skill.RuleName) !== undefined;
                    return <div
                        key="dummy-key"
                        onClick={() => {
                            if (isActive) {
                                setActiveSkills([...activeSkills.filter((act) => act.RuleName !== skill.RuleName)]);
                                dispatch(removeSkill(skill));
                            } else {
                                setActiveSkills([...activeSkills, skill]);
                                dispatch(addSkill(skill));
                            }
                        }}
                        className="skill-selection-item-container"
                        style={{ background: isActive ? "color-mix(in srgb, var(--color-emphasis) 30%, white)" : "" }}
                    >
                        <div key="dummy-key" className="skill-selection-item">{skill.RuleName}</div>
                        <FontAwesomeIcon icon={isActive ? faCheck : faSquare as IconProp} className="skill-selection-icon" />
                    </div>;
                })}
                showSection={showThisSection}
                toggleFunction={toggleShowSection} />;
        })}
        { }
    </>;
};