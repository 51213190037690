import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { setSkillLists } from "../../redux/slices/warriorSlice";
import { useAppDispatch } from "../../redux/store";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { CollapsibleSection } from "./WarriorEquipmentMaintenance";

export const SkillListsSelection = () => {
    const dispatch = useAppDispatch();
    const onChangeHandler = (input: string) => {
        if (selectedSkillLists.includes(input)) {
            setSelectedSkillLists(selectedSkillLists.filter((skillList) => skillList !== input));
            dispatch(setSkillLists(selectedSkillLists.filter((skillList) => skillList !== input)));
            return;
        }
        const skillLists = [...selectedSkillLists];
        if (selectedSkillLists.length === 2) {
            skillLists.shift();
        }
        const selected = skillLists ? [...skillLists, input] : [input];
        setSelectedSkillLists(selected);
        dispatch(setSkillLists(selected));
    };
    const [selectedSkillLists, setSelectedSkillLists] = useState<string[]>([]);
    const [skillListOptions, setSkillListOptions] = useState<string[]>([]);
    useEffect(() => {
        async function fetchSkillLists() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            const skillListOptions = DatabaseProviderInstance.warriors.reduce((acc, warr) => {
                if (warr.Hero && warr.SkillLists) {
                    warr.SkillLists.forEach((skillList) => acc.add(skillList));
                }
                return acc;
            }, new Set<string>());
            setSkillListOptions(Array.from(skillListOptions));
        }
        fetchSkillLists();
    }, []);

    const sectionId = "Select Skill Lists";
    const [showSection, setShowSection] = useState<boolean>(true);
    return <>
        <CollapsibleSection
            withoutHeader={true}
            key={sectionId}
            sectionId={sectionId}
            sectionText={sectionId}
            sectionChildren={skillListOptions.map((skillList) => {
                const isActive = selectedSkillLists?.find((selected) => selected === skillList);
                return <div key="dummy-key" className="skill-selection-item-container">
                    <div key="dummy-key" className="skill-selection-item">{skillList}</div>
                    {isActive ?
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="skill-selection-icon"
                            onClick={() => { onChangeHandler(skillList); }} /> :
                        <FontAwesomeIcon
                            icon={faSquare as IconProp}
                            className="skill-selection-icon"
                            onClick={() => { onChangeHandler(skillList); }} />
                    }
                </div>;
            }) || []}
            showSection={showSection}
            toggleFunction={() => setShowSection(!showSection)} />
    </>;
};