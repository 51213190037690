import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHouseRule } from "../../pages/ProfilePage";
export interface ISettings {
    houseRules: string[];
}

const settingsSlice = createSlice({
    name: "settings",
    initialState: {} as ISettings,
    reducers: {
        setSettings(_state, action: PayloadAction<ISettings>) {
            return action.payload;
        },
        setHouseRules(state, action: PayloadAction<string[]>) {
            state.houseRules = action.payload;
        },
    },
});

export const { setSettings, setHouseRules } = settingsSlice.actions;

export default settingsSlice.reducer;