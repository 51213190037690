import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { NumberInputControl, TextInputControl } from "../components/Input";
import { getSellingPriceByShards } from "../utilities/WarbandHelper";
import { getWarbandSize } from "../utilities/warbandProvider";
import { ButtonControl } from "../components/Button";
import { addFundsSaga, addShardsSaga, changeNameSaga, removeFundsForUpkeepSaga, removeFundsSaga, sellShardsSaga, updateWarriorFromStashSaga } from "../redux/sagas/warbandSaga";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StashEquipmentLine } from "../components/modals/WarriorEquipmentComponent";
import { ICombinedEquipment } from "../types/database";
import { GenericEquipment } from "../components/Equipment";
import { ModalFooter } from "../components/Footer";
import { StashWarriorSelection } from "../components/Dropdown";
const nameRegex = new RegExp("[a-zA-Z0-9]{3,}");
export const EditWarbandPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [navigate, warband]);
    const [newWarbandName, setNewWarbandName] = useState<string>(warband.name);
    const [newCash, setNewCash] = useState<number>(0);
    const [currentItem, setCurrentItem] = useState<ICombinedEquipment | undefined>();
    const hiredSwordsWithUpkeep = warband.warriors.filter((warr) => warr.Upkeep && warr.Upkeep !== "0");
    return <>
        <div>
            <h2>Edit Warband</h2>
            <div className="section-container">
                <div className="header-1">Change warband name</div>
                <TextInputControl label="Warband name (min. 3 characters)" prefilled={warband.name} dispatchCommand={setNewWarbandName} regex={new RegExp(nameRegex)} />
                <ButtonControl label={"Submit name change"} command={() => changeNameSaga(newWarbandName, dispatch)} enabled={newWarbandName.length > 3} />
                <div className="header-1">Add treasures (currently: {warband.treasure})</div>
                <AddShardsControl />
                <SellShardsControl />
                <div style={{ paddingTop: "0.5em" }} className="header-1">Add gold (currently: ${warband.cash})</div>
                <NumberInputControl label="Gold coins" dispatchCommand={(amount) => setNewCash(amount)} prefilled={"0"} />
                <ButtonControl label={`Add ${newCash} gc`} command={() => addFundsSaga(newCash, dispatch)} enabled={true} />
                <ButtonControl label={`Remove ${newCash} gc`} command={() => removeFundsSaga(newCash, dispatch)} enabled={true} />
                <div style={{ paddingTop: "0.5em" }} className="header-1">Pay upkeep for hired swords</div>
                {hiredSwordsWithUpkeep.length > 0 ?
                    hiredSwordsWithUpkeep.map((hs) => <ButtonControl
                        key={`upkeep_${hs.Name}`}
                        label={`Pay ${hs.Upkeep} gc upkeep for ${hs.Name} (${hs.WarriorType})`}
                        command={() => removeFundsForUpkeepSaga(parseInt(hs.Upkeep || ""), dispatch)}
                        enabled={warband.cash > parseInt(hs.Upkeep || "100000", 10)}
                        style={{ height: "2em", width: "calc(100% - 1.5em)" }}
                    />) :
                    null}
            </div>

            <div className="section-container">
                <div className="header-1">Stashed items</div>
                {warband.stash.map((item, index) => <div key={index}> <StashEquipmentLine fullItem={item} setCurrentItem={setCurrentItem} /></div>)}
                {currentItem ? <WarriorSelectionModal item={currentItem} setShowMyPriceModal={setCurrentItem} /> : null}
            </div>
        </div>
    <div className="footer">
        <div style={{ width: "30%" }} className={"dialog-button"} onClick={() => {
        navigate("/overview");
    }}>{"Back"}</div>
    </div>;
    </>;
};
const AddShardsControl = () => {
    const dispatch = useAppDispatch();
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const warband = useAppSelector((state) => state.warband);
    const [newTreasures, setNewTreasures] = useState<number>(warband.treasure);
    const removeOnClick = async () => {
        sellShardsSaga(newTreasures, 0, dispatch);
        setShowSuccess(true);
        setNewTreasures(0);
        const input = document.getElementById("Treasures_nr_input_ctrl") as HTMLInputElement;
        if (input) {
            input.value = "0";
        }
    };
    const onClickHandler = (source: string) => {
        addShardsSaga(newTreasures, source, dispatch);
        setNewTreasures(0);
        setShowSuccess(true);
        const input = document.getElementById("Treasures_nr_input_ctrl") as HTMLInputElement;
        if (input) {
            input.value = "0";
        }
    };
    return <>
        <NumberInputControl label="Treasures" dispatchCommand={(amount) => setNewTreasures(amount)} prefilled={"0"} />
        <ButtonControl style={{width: "calc(100% - 1.5em)"}} label={"Treasures gained from exploration"} command={() => onClickHandler("exploration")} enabled={newTreasures > 0} />
        <ButtonControl style={{width: "calc(100% - 1.5em)"}} label={"Treasures gained from scenario"} command={() => onClickHandler("scenario")} enabled={newTreasures > 0} />
        <ButtonControl label={"Remove shards"} command={removeOnClick} enabled={newTreasures > 0} />
        {showSuccess ? <div style={{ display: "flex" }}><FontAwesomeIcon className="input-icon-ok" icon={faCircleCheck} /><div style={{ paddingLeft: "1em" }}>{"Added shards to warband."}</div></div> : null}
    </>;
};

const SellShardsControl = () => {
    const warband = useAppSelector((state) => state.warband);
    const warbandSize = warband.faction === "HBA" ? getWarbandSize(warband.warriors) - 3 : getWarbandSize(warband.warriors);
    const shardSell: { shards: number; price: number }[] = Array.from(Array(Math.min(8, warband.treasure))).map((_, idx) => ({ shards: idx + 1, price: getSellingPriceByShards(idx + 1, warbandSize) }));
    const [selectedShards, setSelectedShards] = useState<number>(0);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const onClickHandler = async () => {
        sellShardsSaga(selectedShards, getSellingPriceByShards(selectedShards, warbandSize), dispatch);
        setShowSuccess(true);
        setSelectedShards(0);
    };

    return shardSell.length > 0 ?
        <>
            <div style={{ paddingTop: "0.5em" }} className="header-1">Sell treasures (warband size: {warband.faction === "HBA" ? warbandSize + 3 : warbandSize})</div>
            <table style={{ marginBottom: "0.3em" }} className="table">
                <tbody>
                    <tr>
                        <td style={{ fontWeight: "bold" }}># of Shards</td>
                        {
                            shardSell.map((shard) => <td
                                className={shard.shards === selectedShards ? "focused" : ""}
                                onClick={() => setSelectedShards(shard.shards)}
                                key={`shardSell${shard.shards}`}
                                style={{ textAlign: "center" }}>
                                {shard.shards}
                            </td>
                            )
                        }
                    </tr>
                    <tr>
                        <td style={{ fontWeight: "bold", borderBottom: "none" }}>Value in gc</td>
                        {
                            shardSell.map((shard) => <td
                                className={shard.shards === selectedShards ? "focused" : ""}
                                onClick={() => setSelectedShards(shard.shards)}
                                key={`shardSell${shard.price}`}
                                style={{ borderBottom: "none", textAlign: "center" }}>
                                {shard.price}
                            </td>
                            )
                        }
                    </tr>
                </tbody>
            </table>
            <ButtonControl label={`Sell ${selectedShards > 0 ? `${selectedShards}` : ""} shards`} command={onClickHandler} enabled={selectedShards > 0} />
            {showSuccess ? <div style={{ display: "flex" }}><FontAwesomeIcon className="input-icon-ok" icon={faCircleCheck} /><div style={{ paddingLeft: "1em" }}>{"Sold Shards"}</div></div> : null};
        </> : null;
};

const WarriorSelectionModal = ({item, setShowMyPriceModal} : {item: ICombinedEquipment; setShowMyPriceModal: (item: ICombinedEquipment | undefined) => void}) => {
    const dispatch = useAppDispatch();
    const warrior = useAppSelector((state) => state.tempwarrior);
    const submit = () => {
        updateWarriorFromStashSaga([item], warrior, dispatch);
        setShowMyPriceModal(undefined);
    };
    return <div className="modal">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="header-1">Select a warrior to give this item to</div>
            <GenericEquipment equipment={item} />
            <StashWarriorSelection item={item}/>
            <ModalFooter
                submitAction={submit}
                cancelAction={() => setShowMyPriceModal(undefined)} isEnabled={true} />
        </div>
    </div>
</div>;
};
