import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { WarriorSheet } from "../components/warrior/WarriorSheet";
import { WarriorSelection } from "../components/Dropdown";
import { WarriorHeadCountSelection } from "../components/warrior/WarriorHeadcount";
import { WarriorNameInput } from "../components/warrior/WarriorName";
import { PurchaseWeapons } from "../components/dialogs/WarriorEquipmentMaintenance";
import { SpellSelection } from "../components/dialogs/WarriorSpellSelection";
import { DialogPageFooter } from "../components/Footer";
import { buyWarriorSaga } from "../redux/sagas/warriorSaga";
export const AddWarriorPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!warband.name) {
            navigate("/");
        }
    }, [navigate, warband]);
    const warrior = useAppSelector((state) => state.tempwarrior);
    const delta = useAppSelector((state) => state.delta);
    const templog = useAppSelector((state) => state.templog);
    const deltaFunds = delta.reduce((acc, curr) => acc + (curr.value || 0), 0);
    const enabled = !!warrior.Name &&
        warrior.Name.length >= 3 &&
        !!(!warrior.Rules?.find((rule) => rule.RuleName === "Wizard" || rule.RuleName === "Priest") || warrior.Spells?.length) &&
        deltaFunds <= warband.cash;
    return <>
        <div>
            <h2>Add Warrior</h2>

            <div className="section-container">
                <WarriorNameInput />
                <WarriorSelection />
                {warrior.WarriorType ?
                    <>
                        {warrior.Hero ? null : <WarriorHeadCountSelection />}
                        <WarriorSheet warrior={warrior} readOnly={true}/>
                        <PurchaseWeapons />
                        {warrior.Rules?.find((rule) => rule.RuleName === "Wizard" || rule.RuleName === "Priest") ? <SpellSelection editMode={false}/> : null}
                    </> :
                    null
                }
            </div>
        </div>
        <DialogPageFooter submitAction={() => {buyWarriorSaga(templog, warrior, deltaFunds, dispatch); navigate("/overview");}} isEnabled={enabled} />
    </>;
};
