import { IconDefinition, faUserGroup, faUserShield, faShop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export const WarbandControls = () => {
    const navigate = useNavigate();
    const MyDiv = ({ text, icon, clickHandler }: { text: string, icon: IconDefinition, clickHandler?: () => void }) =>
        <div className="control-group" onClick={clickHandler} >
            <FontAwesomeIcon
                icon={icon}
                style={{ height: "1.5em" }}
                className="" />
            <div style={{ width: "60%", fontWeight: "bold" }}>{text}</div>
        </div>;

    return <div className="section-container" style={{ display: "flex", textAlign: "center", textWrap: "balance" }}>
        <div className="footer-section">
            <FontAwesomeIcon icon={faUserGroup} className={"footer-icon"} style={{ height: "1.5em" }} onClick={() => navigate("/add-warrior")} />
            <div style={{ fontSize: "0.85em" }}>Enlist Fighters</div>
        </div>
        <div className={"footer-section"}>
            <FontAwesomeIcon icon={faUserShield} className={"footer-icon"} style={{ height: "1.5em" }} onClick={() => navigate("/add-hiredsword")} />
            <div style={{ fontSize: "0.85em" }}>Recruit Hired Sword</div>
        </div>
        <div className="footer-section">
            <FontAwesomeIcon icon={faShop} className={"footer-icon"} style={{ height: "1.5em" }} onClick={() => navigate("/trading-post")} />
            <div style={{ fontSize: "0.85em" }}>Visit Trading Post</div>
        </div>
    </div>;
};
