import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";
import { IDatabaseInjury } from "../../types/database";
import { IWarrior } from "../../types/warrior";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";

export const WarriorInjuries = ({ givenWarrior }: { givenWarrior: IWarrior }) => {
    const tempWarrior = useAppSelector((state) => state.tempwarrior);
    const warrior = givenWarrior || tempWarrior;
    const [injuries, setInjuries] = useState<IDatabaseInjury[]>([]);
    useEffect(() => {
        async function fetchInjuries() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setInjuries(DatabaseProviderInstance.injuries);
        }
        fetchInjuries();
    }, []);
    return <>
        {warrior.Injuries?.length ?
            <div style={{ width: "100%", padding: "0em 0em 0.5em 0em", display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                <div style={{ fontSize: "0.75em", fontWeight: "bold", paddingTop: "0.2em" }}>Injuries</div>
                {(warrior.Injuries?.map((injury) => {
                    const fullInjury = injuries.find((el) => el.Injury === injury);
                    if (!fullInjury) {
                        return null;
                    }
                    return `${fullInjury.Injury}`;
                }) || []).join(", ")}
            </div> :
            null
        }
    </>;
};
