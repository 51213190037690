import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import { ICombinedEquipment } from "../../types/database";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { EquipmentType } from "../../types/warrior";
import { WarbandEquipmentLine } from "../modals/WarriorEquipmentComponent";
import { ModalEquipment } from "../modals/AddWarriorModal";

export const PurchaseWeapons = () => {
    const warrior = useAppSelector((state) => state.tempwarrior);
    const [showMyPriceModal, setShowMyPriceModal] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<ICombinedEquipment>();
    const [equipmentOptions, setEquipmentOptions] = useState<ICombinedEquipment[]>([]);
    useEffect(() => {
        async function fetchEquipment() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            const equipment = DatabaseProviderInstance.getEquipment(warrior.EquipmentList);
            setEquipmentOptions(equipment);
        }
        fetchEquipment();
    }, [warrior.EquipmentList]);
    const equiTypes = Object.entries(EquipmentType);
    const [showSection, setShowSection] = useState<string[]>([]);
    const toggleShowSection = (section: string) => {
        if (showSection.includes(section)) {
            setShowSection(showSection.filter((show) => show !== section));
        } else {
            setShowSection([...showSection, section]);
        }
    };

    return <>
        <div className="header-1">Purchase equipment</div>
        {equiTypes.map(([type, text]) => {
            const showThisSection = showSection.includes(type);
            const equiList = equipmentOptions.filter((equi) => equi.EquipmentType === type);
            const sectionChildren = equiList.map((equi) => <WarbandEquipmentLine key={equi.EquipmentName} fullItem={equi} setItem={setCurrentItem} showModal={setShowMyPriceModal} />);
            return <CollapsibleSection
                key={type}
                withoutHeader={false}
                sectionId={type}
                sectionText={text}
                sectionChildren={sectionChildren}
                showSection={showThisSection}
                toggleFunction={toggleShowSection} />;
        })}
        { }
        {showMyPriceModal && currentItem ? <ModalEquipment item={currentItem} setShowMyPriceModal={setShowMyPriceModal} /> : null}
    </>;
};

export const CollapsibleSection = (
    {
        sectionId, sectionText, sectionChildren, toggleFunction, showSection, withoutHeader
    }:
        {
            sectionId: string;
            sectionText: string;
            sectionChildren: JSX.Element | JSX.Element[];
            toggleFunction: (sectionId: string) => void;
            showSection: boolean;
            withoutHeader: boolean;
        }
) => {
    return <div key={sectionId} className="skill-selection-container" >
        <div style={{ position: "relative", width: "100%" }} onClick={() => toggleFunction(sectionId)}>
            <div key={sectionId} style={{ paddingLeft: withoutHeader ? "0em" : "0.5em" }} className="skill-selection-header">{sectionText}</div>
            <FontAwesomeIcon icon={showSection ? faAngleUp : faAngleDown} className={"equi-input-header-icon"} />
        </div>
        {showSection ? sectionChildren : null}
    </div>;
};

