import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { WarriorSheet } from "../components/warrior/WarriorSheet";
import { DropdownControl } from "../components/Dropdown";
import { WarriorNameInput } from "../components/warrior/WarriorName";
import { DialogPageFooter } from "../components/Footer";
import { addSkill, addWeapon } from "../redux/slices";
import { IDatabaseRule, IDatabaseTradingPostItem } from "../types/database";
import { DataBaseProvider } from "../utilities/DatabaseProvider";
import { resetWeapons, setSkills } from "../redux/slices/warriorSlice";
import { addWarriorSaga } from "../redux/sagas/warriorSaga";

export const AddPriestOfMorrPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const warband = useAppSelector((state) => state.warband);
    const [rules, setRules] = useState<IDatabaseRule[]>([]);
    const [items, setItems] = useState<IDatabaseTradingPostItem[]>([]);
    useEffect(() => {
        if (!warband.name) {
            navigate("/");
        }
        async function fetchSkills() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setRules(DatabaseProviderInstance.rules);
        }
        async function fetchEquipment() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            const equipment = await DatabaseProviderInstance.getTradingPostItems(warband.faction);
            setItems(equipment);
        }
        fetchEquipment();
        fetchSkills();
    }, [navigate, warband]);
    const setSpeciality = (specialty: string) => {
        setSpecialtyOption(specialty);
        dispatch(resetWeapons());
        const foundProtbyMorr = rules.find((value) => value.RuleName === "Protected by Morr");
        if (foundProtbyMorr) {
            dispatch(setSkills([foundProtbyMorr]));
        }
        let foundSkill;
        const HolyStaffRaw = items.find((value) => value.EquipmentName === "Holy Staff");
        if (!HolyStaffRaw) {
            return;
        }
        const ScytheRaw = items.find((value) => value.EquipmentName === "Scythe");
        if (!ScytheRaw) {
            return;
        }
        const LuckyCharmRaw = items.find((value) => value.EquipmentName === "Lucky Charm");
        if (!LuckyCharmRaw) {
            return;
        }
        const LanternRaw = items.find((value) => value.EquipmentName === "Lantern");
        if (!LanternRaw) {
            return;
        }
        const HolyRelicRaw = items.find((value) => value.EquipmentName === "Holy Relic");
        if (!HolyRelicRaw) {
            return;
        }
        const BlessedWaterRaw = items.find((value) => value.EquipmentName === "Holy Relic");
        if (!BlessedWaterRaw) {
            return;
        }
        const HolyStaff = { ...HolyStaffRaw, Quantity: 1, ListId: "", Price: 0 };
        const Scythe = { ...ScytheRaw, Quantity: 1, ListId: "", Price: 0 };
        const LuckyCharm = { ...LuckyCharmRaw, Quantity: 1, ListId: "", Price: 0 };
        const Lantern = { ...LanternRaw, Quantity: 1, ListId: "", Price: 0 };
        const HolyRelic = { ...HolyRelicRaw, Quantity: 1, ListId: "", Price: 0 };
        const BlessedWater = { ...HolyRelicRaw, Quantity: 3, ListId: "", Price: 0 };

        switch (specialty) {
            case "Death Crier":
                foundSkill = rules.find((value) => value.RuleName === "Death Crier");
                if (foundSkill) {
                    dispatch(addSkill(foundSkill));
                }
                dispatch(addWeapon(Lantern));
                dispatch(addWeapon(HolyStaff));
                break;
            case "Death Warder":
                foundSkill = rules.find((value) => value.RuleName === "Death Warder");
                if (foundSkill) {
                    dispatch(addSkill(foundSkill));
                }
                dispatch(addWeapon(HolyRelic));
                dispatch(addWeapon(HolyStaff));
                break;
            case "Death Seeker":
                foundSkill = rules.find((value) => value.RuleName === "Death Seeker");
                if (foundSkill) {
                    dispatch(addSkill(foundSkill));
                }
                dispatch(addWeapon(HolyStaff));
                dispatch(addWeapon(LuckyCharm));
                break;
            case "Death Marcher":
                foundSkill = rules.find((value) => value.RuleName === "Death Marcher");
                if (foundSkill) {
                    dispatch(addSkill(foundSkill));
                }
                dispatch(addWeapon(BlessedWater));
                dispatch(addWeapon(HolyStaff));
                break;
            // dispatch(addWeapon("3 Blessed Water"));
            case "Death Toucher":
                foundSkill = rules.find((value) => value.RuleName === "Death Toucher");
                if (foundSkill) {
                    dispatch(addSkill(foundSkill));
                }
                dispatch(addWeapon(HolyStaff));
                break;
            case "Death Reaper":
                foundSkill = rules.find((value) => value.RuleName === "Fearsome");
                if (foundSkill) {
                    dispatch(addSkill(foundSkill));
                }
                dispatch(addWeapon(Scythe));
                break;
        }
    };
    const warrior = useAppSelector((state) => state.tempwarrior);
    const delta = useAppSelector((state) => state.delta);
    const templog = useAppSelector((state) => state.templog);
    const deltaFunds = delta.reduce((acc, curr) => acc + (curr.value || 0), 0);
    const [specialtyOption, setSpecialtyOption] = useState<string>();
    const enabled = !!warrior.Name &&
        warrior.Name.length >= 3 &&
        deltaFunds <= warband.cash;
    return <>
        <div>
            <h2>Choose your Priest</h2>

            <div className="section-container">
                <WarriorNameInput />
                <DropdownControl
                    label="Select a specialty for your Priest of Morr"
                    options={["Death Crier", "Death Warder", "Death Seeker", "Death Marcher", "Death Toucher", "Death Reaper"].map((warband) => ({ value: warband, label: `${warband}` }))}
                    command={(input: string) => setSpeciality(input)} />
                {warrior.WarriorType ? <WarriorSheet warrior={warrior} readOnly={true} /> : null}
            </div>
        </div>
        <DialogPageFooter submitAction={() => { addWarriorSaga(templog, warrior, dispatch); navigate("/overview"); }} isEnabled={enabled} />
    </>;
};
