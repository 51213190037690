import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICombinedEquipment } from "../../types/database";

const deltaSlice = createSlice({
    name: "delta",
    initialState: [] as ICombinedEquipment[],
    reducers: {
        addToTempStash(state, action: PayloadAction<ICombinedEquipment>) {
            state.push(action.payload);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        resetTempStash(_state) {
            return [];
        },
    },
});

export const { addToTempStash, resetTempStash } = deltaSlice.actions;

export default deltaSlice.reducer;