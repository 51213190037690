import { useState } from "react";
import { addDelta } from "../../redux/slices";
import { addToTempStash } from "../../redux/slices/stashSlice";
import { useAppDispatch } from "../../redux/store";
import { IDatabaseTradingPostItem } from "../../types/database";
import { GenericEquipment } from "../Equipment";
import { ModalFooter } from "../Footer";
import { NumberInputControl } from "../Input";

export const MyPriceInput = ({ item, setShowMyPriceModal }: { item: IDatabaseTradingPostItem; setShowMyPriceModal(ins: boolean): void }) => {
    const dispatch = useAppDispatch();
    const submit = (price?: number) => {
        dispatch(addDelta({
            command: "Buy Equipment",
            value: -parseInt((price || item.Price).toString(), 10)
        }));
        // dispatch(changeFunds(-parseInt((price || item.Price).toString(), 10)));
        dispatch(addToTempStash({
            ListId: "",
            EquipmentName: item.EquipmentName,
            EquipmentType: item.EquipmentType,
            Distance: item.Distance,
            Strength: item.Strength,
            Upgrade: item.Upgrade,
            ArmourValue: item.ArmourValue,
            EquipmentSubType: item.EquipmentSubType,
            Traits: item.Traits,
            Price: price || item.Price,
            Quantity: 1,
        }));
        setShowMyPriceModal(false);
    };
    const [myPrice, setMyPrice] = useState<number>(0);
    return <div className="modal">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="header-1">Purchase equipment</div>
                <GenericEquipment equipment={item} />
                <div style={{ textWrap: "balance", paddingBottom: "1em" }}>Some items have variable prices or maybe you got them at a discount or even free of charge.</div>

                <NumberInputControl label={"Set the actual price for this item"} dispatchCommand={setMyPrice} prefilled={`${parseInt(item.Price.toString(), 10)}`} />
                <ModalFooter
                    submitAction={() => submit(myPrice)}
                    cancelAction={() => setShowMyPriceModal(false)} isEnabled={true} />
            </div>
        </div>
    </div>;
};

