import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { setInjuries } from "../../redux/slices/warriorSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IDatabaseInjury } from "../../types/database";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { CollapsibleSection } from "./WarriorEquipmentMaintenance";

export const InjurySelection = () => {
    const dispatch = useAppDispatch();
    const warrior = useAppSelector((state) => state.tempwarrior);
    const onChangeHandler = (input: string) => {
        if (selectedInjuries.includes(input)) {
            setSelectedInjuries(selectedInjuries.filter((injury) => injury !== input));
            dispatch(setInjuries(selectedInjuries.filter((injury) => injury !== input)));
            return;
        }
        setSelectedInjuries([...selectedInjuries, input]);
        dispatch(setInjuries([...selectedInjuries, input]));
    };
    const [selectedInjuries, setSelectedInjuries] = useState<string[]>(warrior.Injuries || []);
    const [allInjuries, setAllInjuries] = useState<IDatabaseInjury[]>([]);
    useEffect(() => {
        async function fetchInjuries() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setAllInjuries(DatabaseProviderInstance.injuries);
        }
        fetchInjuries();
    }, []);
    const sectionId = "Select Lasting Injuries";
    const [showSection, setShowSection] = useState<string[]>([sectionId]);
    const toggleShowSection = (section: string) => {
        if (showSection.includes(section)) {
            setShowSection(showSection.filter((show) => show !== section));
        } else {
            setShowSection([...showSection, section]);
        }
    };
    return <>
        <CollapsibleSection
            withoutHeader={true}
            key={sectionId}
            sectionId={sectionId}
            sectionText={sectionId}
            sectionChildren={allInjuries.map((injury) => {
                const isActive = selectedInjuries?.find((selected) => selected === injury.Injury);
                return <div key="dummy-key" className="skill-selection-item-container">
                    <div key="dummy-key" className="skill-selection-item">{injury.Injury}</div>
                    {isActive ?
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="skill-selection-icon"
                            onClick={() => { onChangeHandler(injury.Injury); }} /> :
                        <FontAwesomeIcon
                            icon={faSquare as IconProp}
                            className="skill-selection-icon"
                            onClick={() => { onChangeHandler(injury.Injury); }} />
                    }
                </div>;
            }) || []}
            showSection={showSection.includes(sectionId)}
            toggleFunction={() => toggleShowSection(sectionId)} />
    </>;
};