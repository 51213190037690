import { useAppSelector } from "../../redux/store";
import { IWarrior } from "../../types/warrior";

export const WarriorSpells = ({ givenWarrior }: { givenWarrior: IWarrior }) => {
    const tempWarrior = useAppSelector((state) => state.tempwarrior);
    const warrior = givenWarrior || tempWarrior;
    const isPriest = warrior.Rules?.find((rule) => rule.RuleName === "Priest");
    const isWizard = warrior.Rules?.find((rule) => rule.RuleName === "Wizard");
    return <>
        {warrior.Spells?.length ?
            <div style={{ width: "100%", margin: "0em 0em 0em 0em", padding: "0em 0em 0.5em 0em", display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                <div style={{ fontSize: "0.75em", fontWeight: "bold", paddingTop: "0.2em" }}>{isPriest ? "Prayers" : isWizard ? "Spells" : ""}</div>
                {(warrior.Spells?.map((spell) => `${spell.SpellName} (${spell.Difficulty})`) || []).join(", ")}
            </div> :
            null
        }
    </>;
};
