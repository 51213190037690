import { ICombinedEquipment, IDatabaseRule, IDatabaseSpell } from "./database";

export enum HeroType {
    false,
    true,
    HiredSword
}

export const initialWarrior: IWarrior = {
    Id: window.self.crypto.randomUUID(),
    Name: "",
    WarriorType: "",
    Cost: 0,
    Experience: 0,
    A: 0,
    M: 0,
    WS: 0,
    BS: 0,
    LD: 0,
    W: 0,
    T: 0,
    S: 0,
    I: 0,
    Rating: 5,
    Maximum: 99,
    TotalCost: 0,
    HeadCount: 1,
    EquipmentList: "",
    Hero: HeroType.false,
    Position: 0,
    Ethnicity: "",
    HasFreeDagger: false,
    FixedEquipment: false
};

export const priestOfMorr: IWarrior = {
    Id: window.self.crypto.randomUUID(),
    Name: "",
    WarriorType: "Priest of Morr",
    Cost: 0,
    Experience: 0,
    A: 1,
    M: 4,
    WS: 3,
    BS: 3,
    LD: 8,
    W: 1,
    T: 3,
    S: 3,
    I: 3,
    Rating: 0,
    Maximum: 1,
    TotalCost: 0,
    HeadCount: 1,
    EquipmentList: "",
    Hero: HeroType.HiredSword,
    Position: 0,
    Ethnicity: "Human",
    HasFreeDagger: false,
    FixedEquipment: true,
    Rules: [{RuleName: "Protected by Morr", Text: "This priest never rolls for serious injury after being taken out of action. Instead on a roll of 4+, the Priest gains a 'Secret of Vantor'.", ListId: "", Prerequisite: ""}]
};

export enum Stats {
    "M" = "Movement",
    "WS" = "Weapon Skill",
    "BS" = "Ballistic Skill",
    "S" = "Strength",
    "T" = "Toughness",
    "W" = "Wounds",
    "I" = "Initiative",
    "A" = "Attacks",
    "Ld" = "Leadership",
}

export function isStatProperty(input: unknown): input is Stats {
    return input === Stats.M || input === Stats.WS || input === Stats.BS || input === Stats.S || input === Stats.T || input === Stats.W || input === Stats.I || input === Stats.A || input === Stats.Ld;
}

export interface IWarrior {
    Id: string;
    Name: string;
    WarriorType: string;
    Cost: number;
    Experience: number;
    A: number;
    M: number;
    WS: number;
    BS: number;
    LD: number;
    W: number;
    T: number;
    S: number;
    I: number;
    Equipment?: ICombinedEquipment[];
    SkillLists?: string[];
    Rules?: IDatabaseRule[];
    Rating: number;
    Maximum: number;
    TotalCost: number;
    HeadCount: number;
    EquipmentList: string;
    Hero: HeroType;
    Position: number;
    Spells?:IDatabaseSpell[];
    Injuries?: string[];
    Ethnicity: string;
    HasFreeDagger: boolean;
    FixedEquipment: boolean;
    Upkeep?: string;
}

export enum EquipmentType {
    Melee = "Melee Weapons",
    Ranged = "Ranged Weapons",
    Wargear = "Wargear"
}

export enum WargearSubType {
    Armour = "Armour",
    Alcohol = "Alcohol, Poisons & Potions",
    Miscellaneous = "Miscellaneous",
    Relic = "Relics & Talismans"
}

export const FreeDagger = {
    EquipmentName: "Dagger",
    Quantity: 1,
    EquipmentType: EquipmentType.Melee,
    Strength: "as User",
    Traits: "Enemy armour save",
    Distance: "Melee"
};