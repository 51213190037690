import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { addDelta, loadWarrior, resetDelta } from "../redux/slices";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { ICombinedEquipment } from "../types/database";
import { IWarrior } from "../types/warrior";
import { DataBaseProvider } from "../utilities/DatabaseProvider";

export interface ISelectOption {
    label: string;
    value: string;
    disabled?: boolean;
}

export const WarriorSelection = () => {
    const dispatch = useAppDispatch();
    const warband = useAppSelector((state) => state.warband);
    const storeWarrior = useAppSelector((state) => state.tempwarrior);
    const getWarriorFromOptions = (type: string) => {
        const warriorFound = warriorOptions.find((warrior: IWarrior) => warrior.WarriorType === type);
        if (!warriorFound) {
            throw new Error(`Warrior ${type} not found in Warrior list for Warband`);
        }
        return warriorFound;
    };
    const command = (input: string) => {
        const warriorProfile = getWarriorFromOptions(input);
        dispatch(loadWarrior({ ...warriorProfile, Name: storeWarrior.Name, Id: storeWarrior.Id }));
        dispatch(resetDelta());
        dispatch(addDelta({ command: "loadWarrior", value: warriorProfile.TotalCost * storeWarrior.HeadCount }));
    };
    const isDisabled = (item: IWarrior) => {
        let warriorCount = warband.warriors.filter((warrior) => warrior.WarriorType === item.WarriorType).length;
        if (warband.faction === "MID" && item.WarriorType === "Champion") {
            warriorCount = warband.warriors.filter((warrior) => warrior.WarriorType === item.WarriorType).length + warband.warriors.filter((warrior) => warrior.WarriorType === "Wolf Priest of Ulric").length
        } 
        if (warband.faction === "MID" && item.WarriorType === "Wolf Priest of Ulric" && warband.warriors.filter((warrior) => warrior.WarriorType === "Champion").length > 1) {
            return true;
        } 
        if (warband.faction === "MID" && item.WarriorType === "Wolf Companion") {
            return warband.warriors.filter((warrior) => warrior.WarriorType === "Wolf Priest of Ulric").length !== 1;
        }
        return !!((item.Cost * item.HeadCount) > warband.cash || item.Maximum <= warriorCount);
    };
    const [warriorOptions, setWarriorOptions] = useState<IWarrior[]>([]);
    useEffect(() => {
        async function fetchWarbands() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setWarriorOptions(DatabaseProviderInstance.warriors);
        }
        fetchWarbands();
    }, []);
    return <DropdownControl
        label="Select a Warrior"
        options={warriorOptions.map((warrior) => ({ value: warrior.WarriorType, label: `${warrior.WarriorType} (${warrior.Cost})`, disabled: isDisabled(warrior) }))}
        command={command} />;
};

export const DropdownControl = ({ label, options, command, onClickHandler }: { label: string; options: ISelectOption[]; command: (_input: string) => void; onClickHandler?: () => void }) => {
    const onChangeHandler = (faction: string) => {
        console.log("click");
        setSelectedOption(faction);
        command(faction);
    };
    const optionsIncludingEmpty = [{ label: "", value: "", disabled: true }, ...options];
    const [selectedOption, setSelectedOption] = useState<string>("");
    return <div className="input-container">
        <label className="input-label">{label}</label>
        <select
            id={label}
            value={selectedOption}
            onClick={onClickHandler}
            onChange={(e) => onChangeHandler(e.target.value)}
            className="dropdown-box">
            {optionsIncludingEmpty.map((item) => <option key={item.value} label={item.label} value={item.value} disabled={item.disabled || false}></option>)}
        </select>
        <FontAwesomeIcon icon={faAngleDown} className={"input-icon"} onClick={() => {
            const elem = document.getElementById(label) as HTMLInputElement;
            elem?.showPicker();
        }} />
    </div>;
};


export const HiredSwordSelection = () => {
    const dispatch = useAppDispatch();
    const warband = useAppSelector((state) => state.warband);
    const storeWarrior = useAppSelector((state) => state.tempwarrior);
    const getWarriorFromOptions = (type: string) => {
        const warriorFound = warriorOptions.find((warrior: IWarrior) => warrior.WarriorType === type);
        if (!warriorFound) {
            throw new Error(`Warrior ${type} not found in Warrior list for Warband`);
        }
        return warriorFound;
    };
    const command = (input: string) => {
        const warriorProfile = getWarriorFromOptions(input);
        dispatch(loadWarrior({ ...warriorProfile, Name: storeWarrior.Name }));
        dispatch(resetDelta());
        dispatch(addDelta({ command: "loadWarrior", value: warriorProfile.TotalCost * storeWarrior.HeadCount }));
    };
    const isDisabled = (item: IWarrior) => {
        const warriorCount = warband.warriors.filter((warrior) => warrior.WarriorType === item.WarriorType).length;
        return !!((item.Cost * item.HeadCount) > warband.cash || warriorCount >= 1);
    };
    const [warriorOptions, setWarriorOptions] = useState<IWarrior[]>([]);
    useEffect(() => {
        async function fetchHiredSwords() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setWarriorOptions(DatabaseProviderInstance.hiredSwords);
        }
        fetchHiredSwords();
    }, []);
    return <DropdownControl
        label="Select a Warrior"
        options={warriorOptions.map((warrior) => ({ value: warrior.WarriorType, label: `${warrior.WarriorType} (${warrior.Cost})`, disabled: isDisabled(warrior) }))}
        command={command} />;
};

export const StashWarriorSelection = ({item}:{item: ICombinedEquipment}) => {
    const dispatch = useAppDispatch();
    const warband = useAppSelector((state) => state.warband);
    const command = (input: string) => {
        const foundWarrior = warband.warriors.find((warr) => warr.Name === input);
        if (foundWarrior) {
            dispatch(loadWarrior({...foundWarrior, Equipment: [...(foundWarrior.Equipment || []), item], TotalCost: foundWarrior.TotalCost + parseInt(item.Price as any, 10)}));
        }
        // dispatch(resetDelta());
        // dispatch(addDelta({ command: "loadWarrior", value: warriorProfile.TotalCost * storeWarrior.HeadCount }));
    };
    const isDisabled = (warr: IWarrior) => {
        if (item.Quantity < warr.HeadCount) {
            return true;
        }
        return false;
    };
    return <DropdownControl
        label="Select a Warrior"
        options={warband.warriors.map((warrior) => ({ value: warrior.Name, label: `${warrior.Name} (${warrior.WarriorType})`, disabled: isDisabled(warrior) }))}
        command={command} />;
};