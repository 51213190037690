import { faFloppyDisk, } from "@fortawesome/free-regular-svg-icons";
import { faAngleDown, faHome, faRectangleList, faUser, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { updateStashSaga } from "../redux/sagas/warbandSaga";
import { buyWeaponSaga, cancelWarriorSaga, removeWeaponSaga, returnWeaponSaga, sellWeaponSaga } from "../redux/sagas/warriorSaga";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { ICombinedEquipment } from "../types/database";
import { IWarrior } from "../types/warrior";
import { isDisabledEquipment, weaponInPossession } from "../utilities/WeaponHelper";

export const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isHomeScreen = location.pathname === "/start";
    const isOverview = location.pathname === "/overview";
    const isPdf = location.pathname === "/print-pdf";
    const isProfile = location.pathname === "/profile";
    return <div className="footer">
        <div className="footer-section">
            <FontAwesomeIcon icon={faHome} className={isHomeScreen ? "footer-icon" : "footer-icon disabled"} onClick={() => isHomeScreen ? undefined : navigate("/")} />
            <div>Home</div>
        </div>
        <div className={isHomeScreen ? "footer-section disabled" : "footer-section"}>
            <FontAwesomeIcon icon={faRectangleList} className={isOverview ? "footer-icon" : "footer-icon disabled"} onClick={() => isHomeScreen || isOverview ? undefined : navigate("/overview")} />
            <div>Overview</div>
        </div>
        <div className={isHomeScreen ? "footer-section disabled" : "footer-section"}>
            <FontAwesomeIcon icon={faFloppyDisk as IconDefinition} className={isPdf ? "footer-icon" : "footer-icon disabled"} onClick={() => isHomeScreen || isPdf ? undefined : navigate("/print-pdf")} />
            <div>Save / Print</div>
        </div>
        <div className="footer-section">
            <FontAwesomeIcon icon={faUser} className={isProfile ? "footer-icon" : "footer-icon disabled"} onClick={() => navigate("/profile")} />
            <div>Profile</div>
        </div>
    </div>;
};

export const DialogPageFooter = ({ submitAction, isEnabled }: { submitAction: () => void; isEnabled: boolean }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cancelAction = () => {
        cancelWarriorSaga(dispatch);
        navigate("/overview");
    };
    return <div className="footer">
        <div style={{ width: "30%" }} className={"dialog-button"} onClick={() => cancelAction()}>{"Cancel"}</div>
        <div style={{ width: "30%", background: "var(--color-ok)", color: "var(--color-ok-font)" }} className={isEnabled ? "dialog-button" : "dialog-button disabled"} onClick={() => isEnabled ? submitAction() : undefined}>{"Confirm"}</div>
    </div>;
};

export const DialogDeleteFooter = ({ submitAction, isEnabled }: { submitAction: () => void; isEnabled: boolean }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cancelAction = () => {
        cancelWarriorSaga(dispatch);
        navigate("/overview");
    };
    return <div className="footer">
        <div style={{ width: "30%" }} className={"dialog-button"} onClick={() => cancelAction()}>{"Cancel"}</div>
        <div style={{ width: "30%", background: "var(--color-not-ok)", color: "var(--color-ok-font)" }} className={isEnabled ? "dialog-button" : "dialog-button disabled"} onClick={() => isEnabled ? submitAction() : undefined}>{"DELETE"}</div>
    </div>;
};

export const ModalFooter = ({ submitAction, cancelAction, isEnabled }: { submitAction: () => void; cancelAction: () => void; isEnabled: boolean }) => {
    return <div className="footer">
        <div style={{ width: "30%" }} className={"dialog-button"} onClick={() => cancelAction()}>{"Cancel"}</div>
        <div style={{ width: "30%", background: "var(--color-ok)", color: "var(--color-ok-font)" }} className={isEnabled ? "dialog-button" : "dialog-button disabled"} onClick={() => isEnabled ? submitAction() : undefined}>{"Confirm"}</div>
    </div>;
};
export interface IButtonVisibility {
    canbuy: boolean;
    ownsWeapon: boolean;
}


export const EquipmentModalFooter = ({ equi, warrior, closeModalFn }: {
    equi: ICombinedEquipment,
    warrior: IWarrior,
    closeModalFn: () => void
}) => {
    const warband = useAppSelector((state) => state.warband);
    const delta = useAppSelector((state) => state.delta);
    const dispatch = useAppDispatch();    
    const isCheapEnough = () => equi.Price <= (warband.cash - delta.reduce((acc, curr) => acc + (curr.value || 0), 0));
    const cancelAction = () => {
        closeModalFn();
    };
    const selectOptions = [
        {
            name: "Buy",
            label: `Buy one ${equi.EquipmentName} for ${equi.Price}gc`,
            enabled: isCheapEnough() && !isDisabledEquipment(equi, warrior),
            visibility: true,
            command: () => {buyWeaponSaga(equi, warrior, dispatch);closeModalFn();}
        },
        {
            name: "Sell",
            label: `Sell one ${equi.EquipmentName} for ${Math.ceil(equi.Price / 2)}gc`,
            enabled: !!weaponInPossession(equi, warrior),
            visibility: !!weaponInPossession(equi, warrior),
            command: () => {sellWeaponSaga(equi, warrior, dispatch);closeModalFn();}
        },

        {
            name: "Return",
            label: `Return one ${equi.EquipmentName} for ${equi.Price}gc`,
            enabled: !!weaponInPossession(equi, warrior),
            visibility: !!weaponInPossession(equi, warrior),
            command: () => {returnWeaponSaga(equi, warrior, dispatch);closeModalFn();}
        },
        {
            name: "Remove",
            label: `Remove ${equi.EquipmentName} from warrior`,
            enabled: !!weaponInPossession(equi, warrior),
            visibility: !!weaponInPossession(equi, warrior),
            command: () => {removeWeaponSaga(equi, dispatch);closeModalFn();}
        },
        {
            name: "Stash",
            label: `Move ${equi.EquipmentName} to warband stash`,
            enabled: !!weaponInPossession(equi, warrior),
            visibility: !!weaponInPossession(equi, warrior),
            command: () => {updateStashSaga([equi], 0, dispatch);closeModalFn();}
        },
    ];
    const onChangeHandler = (option: string) => {
        console.log("TRUE")
        const foundOption = selectOptions.find((anOption) => anOption.name === option);
        foundOption?.command();
    };
    return <><div className="footer">
        <div style={{ width: "35%" }} className={"dialog-button"} onClick={() => cancelAction()}>{"Cancel"}</div>
        { !weaponInPossession(equi, warrior) ? <div style={{ width: "35%", background: "var(--color-ok)", color: "var(--color-ok-font)" }} className={"dialog-button"} onClick={() => {buyWeaponSaga(equi, warrior, dispatch);closeModalFn();}}>{"Buy"}</div>: <div
            style={{ width: "35%", background: "var(--color-ok)", color: "var(--color-ok-font)" , display: "flex", justifyContent: "space-evenly"}}
            className={"dialog-button"}
            onClick={() => {
                const elem = document.getElementById("equi-modal-action-box") as HTMLInputElement;
                elem?.showPicker();
            }}>
            {"Select"}
            <FontAwesomeIcon icon={faAngleDown}/>
        </div>
}
    </div>
        <select id="equi-modal-action-box" style={{ visibility: "collapse" }}
                    onChange={(e) => onChangeHandler(e.target.value)}
                    value={"empty"}
        >
            {[{name: "empty", label: "Select a option", enabled: false, visibility: false, command: () => undefined}, ...selectOptions].map((option) => <option key={option.label} label={option.label} value={option.name} disabled={!option.enabled}></option>)}
        </select>
    </>;
};
