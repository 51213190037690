import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DialogPageFooter } from "../components/Footer";
import { EquipmentControl } from "../components/warrior/WarriorEquipment";
import { PurchaseWeapons } from "../components/dialogs/WarriorEquipmentMaintenance";
import { WarriorHeadCountSelection } from "../components/warrior/WarriorHeadcount";
import { WarriorHeaderSection } from "../components/warrior/WarriorHeader";
import { WarriorStats } from "../components/warrior/WarriorStats";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { IWarrior } from "../types/warrior";
import { updateWarriorSaga } from "../redux/sagas/warriorSaga";

export const MaintaintWarriorEquipmentPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const warband = useAppSelector((state) => state.warband);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [navigate, warband]);
    const warrior = useAppSelector((state) => state.tempwarrior);
    const delta = useAppSelector((state) => state.delta);
    const templog = useAppSelector((state) => state.templog);
    const deltaFunds = delta.reduce((acc, curr) => acc + (curr.value || 0), 0);
    const enabled = deltaFunds <= warband.cash;
    const changeWarrior = () => {
        updateWarriorSaga(templog, warrior, deltaFunds, dispatch);
        navigate("/overview");
    };
    return <>
        <div>
            <h2>Warrior equipment</h2>
            <div className="section-container">
                {warrior.Hero ? null : <WarriorHeadCountSelection />}
                <EquipmentWarriorSheet warrior={warrior} />
            </div>
        </div>
        <DialogPageFooter submitAction={changeWarrior} isEnabled={enabled} />
    </>;
};

export const EquipmentWarriorSheet = ({ warrior }: { warrior: IWarrior }) => {
    return <><div className="warrior-card">
        <WarriorHeaderSection givenWarrior={warrior} />
        <WarriorStats givenWarrior={warrior} />
        <EquipmentControl currentWarrior={warrior} />
    </div>
    {!warrior?.FixedEquipment ? <PurchaseWeapons /> : null}
    </>;
};
