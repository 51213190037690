import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import { IDatabaseTradingPostItem } from "../../types/database";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";
import { EquipmentType, WargearSubType } from "../../types/warrior";
import { MyPriceInput } from "../modals/TradingPostModal";
import { TradingPostEquipmentLine } from "../modals/WarriorEquipmentComponent";
import { applyHouseRulesToTradingPost } from "../../utilities/WeaponHelper";

export const TradingPost = () => {
    const warband = useAppSelector((state) => state.warband);
    const settings = useAppSelector((state) => state.settings);
    const [tradingPostItems, setTradingPostItems] = useState<IDatabaseTradingPostItem[]>([]);
    const [showMyPriceModal, setShowMyPriceModal] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<IDatabaseTradingPostItem>();
    useEffect(() => {
        async function fetchEquipment() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            const equipment = await DatabaseProviderInstance.getTradingPostItems(warband.faction);
            const mappedEquipment = applyHouseRulesToTradingPost(settings, equipment);
            setTradingPostItems(mappedEquipment);
        }
        fetchEquipment();
    }, []);
    const equiTypes = Object.entries(EquipmentType);
    const [showSection, setShowSection] = useState<string[]>([]);
    const toggleShowSection = (section: string) => {
        if (showSection.includes(section)) {
            setShowSection(showSection.filter((show) => show !== section));
        } else {
            setShowSection([...showSection, section]);
        }
    };

    return <>
        <div className="header-1">Purchase equipment</div>
        {equiTypes.map(([type, text]) => {
            const showThisSection = showSection.includes(type);
            const equiList = tradingPostItems.filter((equi) => equi.EquipmentType === type);
            let sectionChildren;
            if (type === EquipmentType.Wargear) {
                sectionChildren = Object.keys(WargearSubType).map((subType) => {
                    const filteredEquiList = equiList.filter((equi) => equi.EquipmentSubType === subType);
                    const showThisSubSection = showSection.includes(subType);
                    const subSectionChildren = filteredEquiList.map((equi) => {
                        return <TradingPostEquipmentLine key={equi.EquipmentName} fullItem={equi} setItem={setCurrentItem} showModal={setShowMyPriceModal}/>;
                    });
                    return <CollapsibleSection
                        key={subType}
                        isSubSection={true}
                        sectionId={subType}
                        sectionText={subType === "Alcohol" ? WargearSubType.Alcohol : subType === "Relic" ? WargearSubType.Relic : subType}
                        sectionChildren={subSectionChildren}
                        showSection={showThisSubSection}
                        toggleFunction={toggleShowSection} />;
                });
            } else {
                sectionChildren = equiList.map((equi) => {
                    return <TradingPostEquipmentLine  key={equi.EquipmentName} fullItem={equi} setItem={setCurrentItem} showModal={setShowMyPriceModal}/>;
                });
            }
            return <CollapsibleSection
                key={type}
                isSubSection={false}
                sectionId={type}
                sectionText={text}
                sectionChildren={sectionChildren}
                showSection={showThisSection}
                toggleFunction={toggleShowSection} />;
        })}
        {showMyPriceModal && currentItem ? <MyPriceInput item={currentItem} setShowMyPriceModal={setShowMyPriceModal} /> : null}
    </>;
};

export const CollapsibleSection = (
    {
        sectionId, sectionText, sectionChildren, toggleFunction, showSection, isSubSection
    }:
        {
            sectionId: string;
            sectionText: string;
            sectionChildren: JSX.Element | JSX.Element[];
            toggleFunction: (sectionId: string) => void;
            showSection: boolean;
            isSubSection: boolean;
        }
) => {
    return <div key={sectionId} className="skill-selection-container" style={isSubSection ? {paddingLeft: "1em", width: "calc(100% - 1em)"} : {}}>
        <div style={{ position: "relative", width: "100%" }} onClick={() => toggleFunction(sectionId)}>
            <div key={sectionId} style={{ paddingLeft: "0.5em" }} className="skill-selection-header">{sectionText}</div>
            <FontAwesomeIcon icon={showSection ? faAngleUp : faAngleDown} className={"equi-input-header-icon"} />
        </div>
        {showSection ? sectionChildren : null}
    </div>;
};


