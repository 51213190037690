import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeroType, initialWarrior, IWarrior, Stats } from "../../types/warrior";
import { ICombinedEquipment, IDatabaseRule, IDatabaseSpell } from "../../types/database";

const warriorSlice = createSlice({
    name: "temp-warrior",
    initialState: initialWarrior,
    reducers: {
        setWarriorName(state, action: PayloadAction<string>) {
            state.Name = action.payload;
        },
        loadWarrior(_state, action: PayloadAction<IWarrior>) {
            return action.payload;
        },
        setHeadCount(state, action: PayloadAction<number>) {
            state.HeadCount = action.payload;
        },
        setHero(state, action: PayloadAction<HeroType>) {
            state.Hero = action.payload;
        },
        addWeapon(state, action: PayloadAction<ICombinedEquipment>) {
            const weapon = state.Equipment?.find((item) => item.EquipmentName === action.payload.EquipmentName);
            if (weapon) {
                weapon.Quantity++;
            } else {
                if (state.Equipment) {
                    state.Equipment?.push(action.payload);
                } else {
                    state.Equipment = [action.payload];
                }
            }
            state.TotalCost += action.payload.Price;
        },
        resetWeapons(state) {
            state.Equipment = [];
        },
        removeWeapon(state, action: PayloadAction<ICombinedEquipment>) {
            const weapon = state.Equipment?.find((item) => item.EquipmentName === action.payload.EquipmentName);
            if (weapon && weapon.Quantity > 1) {
                weapon.Quantity--;
            } else {
                const idx = state.Equipment?.findIndex((item) => item.EquipmentName === action.payload.EquipmentName);
                if (idx !== undefined) {
                    state.Equipment?.splice(idx, 1);
                }
            }
            state.TotalCost = (state.Cost + (state.Equipment?.reduce((acc, item) => acc + (item.Quantity * item.Price), 0) || 0)) * state.HeadCount;
        },
        addSpell(state, action: PayloadAction<IDatabaseSpell>) {
            if (state.Spells) {
                state.Spells.push(action.payload);
            } else {
                state.Spells = [action.payload];
            }
        },
        setSpell(state, action: PayloadAction<IDatabaseSpell[]>) {
            state.Spells = action.payload;
        },
        upgradeSpell(state, action: PayloadAction<IDatabaseSpell>) {
            const currentSpells = state.Spells;
            if (currentSpells) {
                const spellIndex = currentSpells.findIndex((item) => item.SpellName === action.payload.SpellName);
                if (spellIndex !== -1 && state.Spells) {
                    state.Spells[spellIndex] = action.payload;
                }
            }
        },
        setInjuries(state, action: PayloadAction<string[]>) {
            state.Injuries = action.payload;
        },
        addSkill(state, action: PayloadAction<IDatabaseRule>) {
            if (state.Rules) {
                state.Rules?.push(action.payload);
            } else {
                state.Rules = [action.payload];
            }
        },
        setSkills(state, action: PayloadAction<IDatabaseRule[]>) {
            state.Rules = action.payload;
        },
        removeSkill(state, action: PayloadAction<IDatabaseRule>) {
            if (state.Rules) {
                const idx = state.Rules?.findIndex((item) => item.RuleName === action.payload.RuleName);
                if (idx !== undefined) {
                    state.Rules?.splice(idx, 1);
                }
            }
        },
        increaseXP(state) {
            state.Experience += 1;
        },
        increaseStat(state, action: PayloadAction<string>) {
            switch (action.payload) {
                case Stats.M:
                    state.M++;
                    break;
                case Stats.WS:
                    state.WS++;
                    break;
                case Stats.BS:
                    state.BS++;
                    break;
                case Stats.S:
                    state.S++;
                    break;
                case Stats.T:
                    state.T++;
                    break;
                case Stats.W:
                    state.W++;
                    break;
                case Stats.I:
                    state.I++;
                    break;
                case Stats.A:
                    state.A++;
                    break;
                case Stats.Ld:
                    state.LD++;
                    break;
                default:
                    throw new Error(`Stat ${action.payload} not found.`);
            }
        },
        setSkillLists(state, action: PayloadAction<string[]>) {
            state.SkillLists = action.payload;
        }
    },
});

export const { setSkills, resetWeapons, addWeapon, addSpell, addSkill, increaseStat, increaseXP, setHeadCount, setHero, setWarriorName, loadWarrior, removeWeapon, removeSkill, setSpell, upgradeSpell, setInjuries, setSkillLists } = warriorSlice.actions;

export default warriorSlice.reducer;