import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DialogPageFooter } from "../components/Footer";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { TradingPost } from "../components/dialogs/TradingPost";
import { updateStashSaga } from "../redux/sagas/warbandSaga";

export const TradingPostPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const warband = useAppSelector((state) => state.warband);
    const tempStash = useAppSelector((state) => state.tempStash);
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [navigate, warband]);
    const delta = useAppSelector((state) => state.delta);
    const deltaFunds = delta.reduce((acc, curr) => acc + (curr.value || 0), 0);
    const enabled = deltaFunds <= warband.cash;
    return <>
        <div>
            <h2>Trading Post</h2>
            <div className="section-container">
                <TradingPost />
            </div>
        </div>
        <DialogPageFooter submitAction={() => {updateStashSaga(tempStash, deltaFunds, dispatch); navigate("/overview");}} isEnabled={enabled} />
    </>;
};