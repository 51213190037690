import { UnknownAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ICombinedEquipment } from "../../types/database";
import { IWarband } from "../../types/warband";
import { initialWarrior, IWarrior } from "../../types/warrior";
import { addWarbandLog, changeFunds, resetDelta, addToStash, loadWarrior } from "../slices";
import { IDelta } from "../slices/deltaSlice";
import { resetTempStash } from "../slices/stashSlice";
import { changeTreasures, removeFromStash, setWarbandName, updateWarrior } from "../slices/warbandSlice";

export const updateStashSaga = (
    tempstash: ICombinedEquipment[], 
    deltaFunds: number,
    dispatch: ThunkDispatch<{
        tempstash: ICombinedEquipment;
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    tempstash.forEach((item) => {
        dispatch(addWarbandLog([{ command: "Add item to stash", value: item.EquipmentName, cost: item.Price }]));
        dispatch(addToStash(item));
    });
    dispatch(changeFunds(deltaFunds));
    dispatch(resetDelta());
    dispatch(resetTempStash());
};

export const sellShardsSaga = (
    shards: number, 
    deltaFunds: number,
    dispatch: ThunkDispatch<{
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    dispatch(changeTreasures(-shards));
    dispatch(addWarbandLog([{ command: "Sell shards", value: shards.toString(), cost: deltaFunds }]));
    dispatch(changeFunds(deltaFunds));
    dispatch(resetDelta());
};

export const addShardsSaga = (
    shards: number, 
    source: string,
    dispatch: ThunkDispatch<{
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    dispatch(changeTreasures(shards));
    dispatch(addWarbandLog([{ command: `Add shards from ${source}`, value: shards.toString() }]));
    dispatch(resetDelta());
};

export const changeNameSaga = (
    newName: string, 
    dispatch: ThunkDispatch<{
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    dispatch(setWarbandName(newName));
    dispatch(addWarbandLog([{ command: "Set warband name", value: `${newName}` }]));
};

export const addFundsSaga = (
    funds: number, 
    // source: string,
    dispatch: ThunkDispatch<{
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    dispatch(changeFunds(funds));
    dispatch(addWarbandLog([{ command: "Add gc", value: funds.toString() }]));
    dispatch(resetDelta());
};

export const removeFundsSaga = (
    funds: number, 
    // source: string,
    dispatch: ThunkDispatch<{
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    dispatch(changeFunds(-funds));
    dispatch(addWarbandLog([{ command: "Remove gc", value: (-funds).toString() }]));
    dispatch(resetDelta());
};

export const removeFundsForUpkeepSaga = (
    funds: number, 
    dispatch: ThunkDispatch<{
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    dispatch(changeFunds(-funds));
    dispatch(addWarbandLog([{ command: "Remove gc for hired sword upkeep", value: (-funds).toString() }]));
    dispatch(resetDelta());
};

export const updateWarriorFromStashSaga = (
    tempstash: ICombinedEquipment[], 
    warrior: IWarrior, 
    dispatch: ThunkDispatch<{
        tempstash: ICombinedEquipment;
        warband: IWarband;
        delta: IDelta[];
    }, undefined, UnknownAction>
) => {
    tempstash.forEach((item) => {
        dispatch(addWarbandLog([{ command: `Move item from stash to ${warrior.Name}` , value: item.EquipmentName }]));
        dispatch(removeFromStash(item));
    });
    dispatch(updateWarrior(warrior));
    dispatch(resetDelta());
    dispatch(loadWarrior(initialWarrior));
    dispatch(resetTempStash());
};
