export const getSellingPriceByShards = (shards: number, warbandSize: number): number => {
    switch (shards) {
        case 1:
            return warbandSize < 4 ? 45 : warbandSize < 7 ? 40 : warbandSize < 10 ? 35 : warbandSize < 13 ? 30 : warbandSize < 16 ? 30 : 25;
        case 2:
            return warbandSize < 4 ? 60 : warbandSize < 7 ? 55 : warbandSize < 10 ? 50 : warbandSize < 13 ? 45 : warbandSize < 16 ? 40 : 35;
        case 3:
            return warbandSize < 4 ? 75 : warbandSize < 7 ? 70 : warbandSize < 10 ? 65 : warbandSize < 13 ? 60 : warbandSize < 16 ? 55 : 50;
        case 4:
            return warbandSize < 4 ? 90 : warbandSize < 7 ? 80 : warbandSize < 10 ? 70 : warbandSize < 13 ? 65 : warbandSize < 16 ? 60 : 55;
        case 5:
            return warbandSize < 4 ? 110 : warbandSize < 7 ? 100 : warbandSize < 10 ? 90 : warbandSize < 13 ? 80 : warbandSize < 16 ? 70 : 65;
        case 6:
            return warbandSize < 4 ? 120 : warbandSize < 7 ? 110 : warbandSize < 10 ? 100 : warbandSize < 13 ? 90 : warbandSize < 16 ? 80 : 70;
        case 7:
            return warbandSize < 4 ? 145 : warbandSize < 7 ? 130 : warbandSize < 10 ? 120 : warbandSize < 13 ? 110 : warbandSize < 16 ? 100 : 90;
        case 8:
            return warbandSize < 4 ? 155 : warbandSize < 7 ? 140 : warbandSize < 10 ? 130 : warbandSize < 13 ? 120 : warbandSize < 16 ? 110 : 100;
        default:
            return 0;
    }
};