import axios, { AxiosResponse } from "axios";
import { ICombinedEquipment, IDatabaseEquipment, IDatabaseEthnicMaximums, IDatabaseHiredSword, IDatabaseInjury, IDatabaseProviderInstance, IDatabaseRule, IDatabaseSettings, IDatabaseSpell, IDatabaseSpellcaster, IDatabaseTradingPostItem, IDatabaseTrait, IDatabaseWarband, IDatabaseWarrior, ISpellcaster, IUserWarband } from "../types/database";
import { HeroType, IWarrior } from "../types/warrior";
import { IWarband } from "../types/warband";
import { ISettings } from "../redux/slices/settingsSlice";
import { applyHouseRules } from "./WeaponHelper";

export const DataBaseProvider = class {

    public warbands: IDatabaseWarband[] = [];
    public rules: IDatabaseRule[] = [];
    public injuries: IDatabaseInjury[] = [];
    public warriors: IWarrior[] = [];
    public hiredSwords: IWarrior[] = [];
    public traits: IDatabaseTrait[] = [];
    private equipment: ICombinedEquipment[] = [];
    private maximums: IDatabaseEthnicMaximums[] = [];
    private static instance: IDatabaseProviderInstance;

    public static getInstance = async () => {
        if (!this.instance) {
            this.instance = new DataBaseProvider();
            await this.instance.init();
        }
        return this.instance;
    };

    public init = async () => {
        this.warbands = await this.getWarbands();
        this.rules = await this.getRules();
        this.injuries = await this.getInjuries();
        this.maximums = await this.getMaximums();
        this.traits = await this.getTraits();
    };

    private callGetApi = async (endpoint: string, parameter?: { key: string, value: string }) => {
        try {
            const response: AxiosResponse = await axios.get(`https://mordheim-companion.com/index.php/${endpoint}${parameter ? `?${parameter.key}=${parameter.value}` : ""}`);
            const responseData: unknown[] = response.data;
            // Process the response data
            return responseData;
        } catch (error) {
            // Handle the error
            throw new Error(`Error fetching ${endpoint}: ${error}`);
        }
    };

    private getWarbands = async (): Promise<IDatabaseWarband[]> => {
        return this.callGetApi("warbands") as Promise<IDatabaseWarband[]>;
    };
    private getRules = async (): Promise<IDatabaseRule[]> => {
        return this.callGetApi("rules") as Promise<IDatabaseRule[]>;
    };
    private getTraits = async (): Promise<IDatabaseTrait[]> => {
        return this.callGetApi("traits") as Promise<IDatabaseTrait[]>;
    };
    private getInjuries = async (): Promise<IDatabaseInjury[]> => {
        return this.callGetApi("injuries") as Promise<IDatabaseInjury[]>;
    };
    private getMaximums = async (): Promise<IDatabaseEthnicMaximums[]> => {
        return this.callGetApi("maximums") as Promise<IDatabaseEthnicMaximums[]>;
    };
    private getSpellcasterFromDatabase = async (warriorType: string): Promise<IDatabaseSpellcaster[]> => {
        return this.callGetApi("spellcaster", { key: "warriorType", value: warriorType }) as Promise<IDatabaseSpellcaster[]>;
    };
    private getSpells = async (magicType: string): Promise<IDatabaseSpell[]> => {
        return this.callGetApi("spells", { key: "magicType", value: magicType }) as Promise<IDatabaseSpell[]>;
    };
    public getWarriors = async (warbandId: string): Promise<IDatabaseWarrior[]> => {
        return this.callGetApi("warriors", { key: "warbandId", value: warbandId }) as Promise<IDatabaseWarrior[]>;
    };
    public getHiredSwords = async (warbandId: string): Promise<IDatabaseHiredSword[]> => {
        return this.callGetApi("hiredswords", { key: "warbandId", value: warbandId }) as Promise<IDatabaseHiredSword[]>;
    };
    public getTradingPostItems = async (warbandId: string): Promise<IDatabaseTradingPostItem[]> => {
        return this.callGetApi("tradingpost", { key: "warbandId", value: warbandId }) as Promise<IDatabaseTradingPostItem[]>;
    };
    public searchWarbands = async (userId: string): Promise<IUserWarband[]> => {
        return this.callGetApi("warbandstore", { key: "userId", value: userId }) as Promise<IUserWarband[]>;
    };
    public fetchSettings = async (userId: string): Promise<IDatabaseSettings[]> => {
        return this.callGetApi("settings", { key: "userId", value: userId }) as Promise<IDatabaseSettings[]>;

    };
    private getListsEquipment = async (listIds: string[]) => {
        const parameters = listIds.map((listId) => `listId[]=${listId}`).join("&");
        try {
            const response: AxiosResponse = await axios.get(`https://mordheim-companion.com/index.php/equipment?${parameters}`);
            const responseData: IDatabaseEquipment[] = response.data;
            // Process the response data
            return responseData;
        } catch (error) {
            // Handle the error
            throw new Error(`Error fetching warbands: ${error}`);
        }
    };
    public getEquipment = (listId: string) => {
        return this.equipment.filter((equi) => equi.ListId === listId);
    };
    public getSpellcaster = async (warriorType: string): Promise<ISpellcaster> => {
        const spellcaster = (await this.getSpellcasterFromDatabase(warriorType))[0];
        const spells = await this.getSpells(spellcaster.MagicType);
        return { ...spellcaster, SpellOptions: spells };
    };

    public getSkillOptions = (listId: string) => this.rules.filter((entry) => entry.ListId === listId);

    public getWarbandHumanReadableType = (warbandType: string) => this.warbands.find((entry) => entry.Id === warbandType)?.Name || "";

    private getEquipmentOptions = async (listIds: string[], settings: ISettings) => {
        const filteredIds = listIds.filter((listId) => !this.equipment.some((entry) => entry.ListId === listId));
        if (filteredIds.length > 0) {
            const additionalEquipment = await this.getListsEquipment(filteredIds);
            const mappedEquipment = applyHouseRules(settings, additionalEquipment);
            this.equipment = this.equipment.concat(mappedEquipment);
            return this.equipment;
        }
        return this.equipment.filter((entry) => entry.ListId in listIds);
    };
    public getDataBaseRules = (ruleNames: string[]) => {
        return ruleNames.map((rule: string) => {
            const foundRule = this.rules.find((dbRule: IDatabaseRule) => dbRule.RuleName === rule);
            if (!foundRule) {
                throw new Error(`Rule ${rule} not found. Please add metadata to the Rules table.`);
            }
            return foundRule;
        });
    };

    public getWarbandMetadata = async (faction: string, settings: ISettings) => {

        const warband = this.warbands.find((warband) => warband.Id === faction);
        if (!warband) {
            throw new Error(`Warband ${warband} not found. Please add metadata to the Warbands table.`);
        }
        const warriors = await this.getWarriors(faction);
        const hiredSwords = await this.getHiredSwords(faction);
        const hiredLists = hiredSwords.filter((hir) => !!hir.EquipmentList).map((hir) => hir.EquipmentList);
        const equipmentSet = new Set<string>(warriors.filter((warrior) => !!warrior.EquipmentList).map((warrior) => warrior.EquipmentList).concat(hiredLists));

        const listArray = await this.getEquipmentOptions(Array.from(equipmentSet), settings);

        this.warriors = warriors.map((warrior) => {
            const hasFreeDagger = !!listArray.filter((entry) => entry.ListId === warrior.EquipmentList).find((entry) => entry.EquipmentName === "Dagger");
            const ruleNames: string[] = (warrior.Rules && warrior.Rules.split(";").map((entry) => entry.trim())) || [];
            return {
                ...warrior,
                Id: "",
                Name: "",
                SkillLists: (warrior.SkillLists && warrior.SkillLists.split(";")) || [],
                Equipment: [],
                Rules: this.getDataBaseRules(ruleNames),
                TotalCost: warrior.Cost,
                HeadCount: 1,
                Hero: warrior.Hero ? HeroType.true : HeroType.false,
                Position: 0,
                Ethnicity: warrior.Ethnicity,
                HasFreeDagger: hasFreeDagger,
                FixedEquipment: false
            };
        });

        this.hiredSwords = hiredSwords.map((hiredSword) => {
            const ruleNames: string[] = (hiredSword.Rules && hiredSword.Rules.split(";").map((entry) => entry.trim())) || [];
            const equipment = listArray.filter((equi) => equi.ListId === hiredSword.EquipmentList && equi.EquipmentName !== "Baton");
                        return {
                ...hiredSword,
                Cost: parseInt(hiredSword.Cost as any, 10),
                Id: "",
                Name: "",
                SkillLists: (hiredSword.SkillLists && hiredSword.SkillLists.split(";")) || [],
                Equipment: equipment,
                Rules: this.getDataBaseRules(ruleNames),
                TotalCost: parseInt(hiredSword.Cost as any, 10),
                HeadCount: 1,
                Hero: HeroType.HiredSword,
                Position: 0,
                Ethnicity: hiredSword.Ethnicity,
                HasFreeDagger: false,
                FixedEquipment: !equipment.some((equi) => equi.Optional === 1),
                Maximum: 1,
                Upkeep: hiredSword.Upkeep
            };
        });
        return warband;
    };

    public getEthnicMaximum = (ethnicity: string) => {
        const foundMax = this.maximums.find((max) => max.Ethnicity === ethnicity);
        if (!foundMax) {
            throw new Error(`Ethnicity ${ethnicity} not found. Please add metadata to the EthnicMaximums table.`);
        }
        return foundMax;
    };
    public saveWarband = async (warband: IWarband, user: string) => {
        const jsonObj = { UserId: user, WarbandId: warband.id, WarbandJson: JSON.stringify(warband) };
        try {
            const response: AxiosResponse = await axios.post("https://mordheim-companion.com/index.php/warbandstore", jsonObj, {
                headers: { "Content-Type": "application/json" },
            });
            const responseData = response.data;
            // Process the response data
            return responseData;
        } catch (error) {
            // Handle the error
            throw new Error(`Error saving warband: ${error}`);
        }
    };

    public saveSettings = async (settings: ISettings, user: string) => {
        const jsonObj = { UserId: user, SettingsJson: JSON.stringify(settings) };
        try {
            const response: AxiosResponse = await axios.post("https://mordheim-companion.com/index.php/settings", jsonObj, {
                headers: { "Content-Type": "application/json" },
            });
            const responseData = response.data;
            // Process the response data
            return responseData;
        } catch (error) {
            // Handle the error
            throw new Error(`Error saving settings: ${error}`);
        }
    };

    public deleteWarband = async (warbandId: string) => {
        try {
            const response: AxiosResponse = await axios.delete(`https://mordheim-companion.com/index.php/deletewarband?warbandId=${warbandId}`);
            const responseData: boolean = response.data;
            // Process the response data
            return responseData;
        } catch (error) {
            // Handle the error
            throw new Error(`Error deleting warbands: ${error}`);
        }
    };
};