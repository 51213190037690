export const isLevelUp = (xp: number): boolean => {
    switch (xp) {
        case 2:
        case 4:
        case 6:
        case 8:
        case 11:
        case 14:
        case 17:
        case 20:
        case 24:
        case 28:
        case 32:
        case 36:
        case 41: return true;
        default: return false;
    }
};