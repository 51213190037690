import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExportWarbandButton, GeneratePdfButton, SaveButton } from "../components/Button";
import { Footer } from "../components/Footer";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { PdfWarriorCard } from "../components/pdf/PdfWarriorCard";
import { WarbandPdfKPIs } from "../components/warband/WarbandKpis";
import { useAppSelector } from "../redux/store";
import { IDatabaseTrait } from "../types/database";
import { DataBaseProvider } from "../utilities/DatabaseProvider";

export const PdfControls = ({ setShowLoading }: { setShowLoading: (show: boolean) => void }) => {
    return <React.Fragment>
        <div className="section-container">
            <div className="header-1">Pdf Export</div>
            <GeneratePdfButton showLoading={setShowLoading} />
        </div>
        <div className="section-container">
            <div className="header-1">Save Warband</div>
            <SaveButton />

        </div>
        <div className="section-container">
            <div className="header-1">Export Warband File</div>
            <ExportWarbandButton showLoading={setShowLoading} />
        </div>
    </React.Fragment>;
};


export const PdfPage = () => {
    const navigate = useNavigate();
    const warband = useAppSelector((state) => state.warband);
    const [showLoading, setShowLoading] = React.useState(false);
    const [heights, setHeights] = React.useState<number[]>([]);
    const [traits, setTraits] = useState<IDatabaseTrait[]>([]);
    useEffect(() => {
        if (!warband.name) {
            navigate("/");
        }
        setHeights(warband.warriors.map((warrior) => document.getElementById(`${warrior.Name}_pdfcard`)?.clientHeight || 0));
        async function fetchEquipment() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setTraits(await DatabaseProviderInstance.traits);
        }
        fetchEquipment();
    }, [navigate, warband, PrintDummyPage]);

    const warriortraits = warband.warriors.reduce((acc, warr) => {
        if (traits.length === 0) {
            return acc;
        }
        if (warr.Equipment) {
            warr.Equipment.forEach((equip) => {
                if (equip.Traits) {
                    const traitList = equip.Traits.trim().split(";");
                    traitList.forEach((trait) => {
                        const fulltrait = traits.find(t => t.Trait === trait);
                        if (fulltrait) {
                            acc.add(fulltrait);
                        }
                    });
                }
            });
        }
        return acc;
    }, new Set<IDatabaseTrait>());

    return <React.Fragment>
        <h2>{warband.name}</h2>
        <PdfControls setShowLoading={setShowLoading} />
        <PrintDummyPage />
        <WarbandInfoPage />
        <PrintPage heights={heights} />
        <WeaponTraitsPage traits={Array.from(warriortraits)} />
        <Footer />
        {showLoading ? <LoadingIndicator /> : null}
    </React.Fragment>;
};

export const PrintPage = ({ heights }: { heights: number[] }) => {
    function splitArrayBySum(arr: number[], maxSum: number) {
        const result = [];
        let currentArray: number[] = [];
        let currentSum = 0;

        arr.forEach((num, idx) => {
            if (currentSum + num > maxSum) {
                result.push(currentArray);
                currentArray = [idx];
                currentSum = num;
            } else {
                currentArray.push(idx);
                currentSum += num;
            }
        });

        if (currentArray.length > 0) {
            result.push(currentArray);
        }
        return result;
    }
    const splittedArry = splitArrayBySum(heights, 850);
    const warband = useAppSelector((state) => state.warband);
    const liste = warband.warriors.map((warrior) => <PdfWarriorCard key={`${warrior.Name}_pdfcard`} warrior={warrior} />);
    const createContainer = (warrior: JSX.Element[]) => <div className="pdf-container">{warrior}</div>;

    const pairedArry = splittedArry.map((arr) => {
        return <div key="" style={{ display: "flex", flexDirection: "column" }}>{arr.map((idx) => liste[idx])}</div>;
    });
    function processInPairs(arr: JSX.Element[]) {
        const containers = [];
        for (let i = 0; i < arr.length; i += 2) {
            const firstElement = arr[i];
            const secondElement = arr[i + 1] || null; // Handle the case where there is no second element
            containers.push(createContainer([firstElement, secondElement])); // Filter out null values
        }
        return <>{containers}</>;
    }
    return processInPairs(pairedArry);
};

export const PrintDummyPage = () => {
    const warband = useAppSelector((state) => state.warband);
    return <div style={{ position: "absolute", top: "-10000px" }}>{warband.warriors.map((warrior) => <PdfWarriorCard key={`${warrior.Name}_pdfcard`} warrior={warrior} />)}</div>;
};


export const WarbandInfoPage = () => {
    const warband = useAppSelector((state) => state.warband);
    return <div style={{ display: "flex", flexDirection: "column" }} className="pdf-container">
        <h2 style={{ fontSize: "2em", color: "var(--color-middle-emphasis)" }}>{warband.name}</h2>
        <WarbandPdfKPIs />
    </div>;
};
export const WeaponTraitsPage = ({ traits }: { traits: IDatabaseTrait[] }) => {
    if (traits.length === 0) {
        return null;
    }
    const warband = useAppSelector((state) => state.warband);
    return <div className="pdf-container" style={{ display: "flex", flexDirection: "column" }}>
        <h2 style={{ textAlign: "center" }}>Weapon traits</h2>
        <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", height: "95%" }}>
            {traits.map((trait) => <div key="" style={{ width: "47%" }}>
                <div className="header-1" key="">{trait.Trait}</div>
                <div className="pdf-text" key="">{trait.Text}</div>
            </div>
            )}
        </div>
    </div>;
};