import { useAppSelector } from "../../redux/store";
import { IWarrior } from "../../types/warrior";
import { EquipmentControl, EquipmentPdfControl } from "./WarriorEquipment";
import { WarriorActions } from "./WarriorActions";
import { WarriorStats } from "./WarriorStats";
import { WarriorHeaderSection } from "./WarriorHeader";
import { WarriorPdfRules, WarriorRules } from "./WarriorRules";
import { WarriorInjuries } from "./WarriorInjuries";
import { WarriorSpells } from "./WarriorSpells";
import { IDatabaseTrait } from "../../types/database";

export const OverviewWarriorSheets = () => {
    const warriors = useAppSelector((state) => state.warband.warriors);
    const sortedWarriors = [...warriors].sort((a: IWarrior, b: IWarrior) => a.Position - b.Position);
    return <div className="section-container">
        <div className="header-1">Fighter List</div>
        {sortedWarriors.map((warrior) => <WarriorSheet key="dummy-key" warrior={warrior} readOnly={false}/>)}
    </div>;
};

export const WarriorSheet = ({ warrior, readOnly }: { warrior: IWarrior; readOnly: boolean }) => {

    return <div className="warrior-card" onClick={() => {
        const elem = document.getElementById(warrior.Name) as HTMLInputElement;
        elem?.showPicker();
    }}>
        <WarriorHeaderSection givenWarrior={warrior} />
        <WarriorStats givenWarrior={warrior} />
        <WarriorRules givenWarrior={warrior} />
        <WarriorSpells givenWarrior={warrior} />
        <WarriorInjuries givenWarrior={warrior} />
        <EquipmentControl currentWarrior={warrior} />
        <WarriorActions givenWarrior={warrior} readOnly={readOnly}/>
    </div>;
};

export const WarriorPdfSheet = ({ warrior }: { warrior: IWarrior }) => {

    return <div style={{border: "none"}}className="warrior-card" onClick={() => {
        const elem = document.getElementById(warrior.Name) as HTMLInputElement;
        elem?.showPicker();
    }}>
        <WarriorHeaderSection givenWarrior={warrior} />
        <WarriorStats givenWarrior={warrior} />
        <WarriorPdfRules givenWarrior={warrior} />
        <WarriorInjuries givenWarrior={warrior} />
        <EquipmentPdfControl currentWarrior={warrior}/>
        <WarriorActions givenWarrior={warrior} readOnly={true}/>
    </div>;
};
