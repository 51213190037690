import React from "react";
import { IDatabaseTrait } from "../../types/database";
import { IWarrior } from "../../types/warrior";
import { WarriorPdfSheet } from "../warrior/WarriorSheet";

export const PdfWarriorCard = ({ warrior }: { warrior: IWarrior }) => {
    return <React.Fragment>
        <div id={`${warrior.Name}_pdfcard`} className="new-pdf-unit-card">
        <WarriorPdfSheet warrior={warrior} />
        </div> 
    </React.Fragment>;
};