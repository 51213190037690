import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import { IDatabaseTrait } from "../../types/database";
import { FreeDagger, IWarrior } from "../../types/warrior";
import { DataBaseProvider } from "../../utilities/DatabaseProvider";

export const EquipmentControl = ({ currentWarrior }: { currentWarrior?: IWarrior }) => {
    let warrior = useAppSelector((state) => state.tempwarrior);
    if (currentWarrior) {
        warrior = currentWarrior;
    }
    const currentEquipment = warrior.Equipment || [];
    return <>
        {warrior.EquipmentList?.length || currentEquipment ?
                <div style={{ width: "100%", padding: "0em 0em 0.5em 0em", display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                    <div style={{ fontSize: "0.75em", fontWeight: "bold", paddingTop: "0.2em" }}>Equipment </div>
                    <div style={{ padding: "0em 0em 0.5em 0em" }}>{(warrior.HasFreeDagger ? ["Dagger (Free)"] : []).concat(currentEquipment.map((equi) => `${equi.Quantity > 1 ? `${equi.Quantity}x ` : ""}${equi.EquipmentName}`)).join(", ")}</div>
                </div>
            : null
        }
    </>;
};

export const EquipmentPdfControl = ({ currentWarrior }: { currentWarrior?: IWarrior }) => {
    let warrior = useAppSelector((state) => state.tempwarrior);
    if (currentWarrior) {
        warrior = currentWarrior;
    }
    const [traits, setTraits] = useState<IDatabaseTrait[]>([]);
    useEffect(() => {
        async function fetchEquipment() {
            const DatabaseProviderInstance = await DataBaseProvider.getInstance();
            setTraits(await DatabaseProviderInstance.traits);
        }
        fetchEquipment();
    }, []);
    const currentEquipment = warrior.Equipment || [];
    const meleeWeapons = warrior.Equipment?.filter((weapon) => weapon.EquipmentType === "Melee");
    const rangedWeapons = warrior.Equipment?.filter((weapon) => weapon.EquipmentType === "Ranged");
    const allSorted = [...(warrior.HasFreeDagger ? [FreeDagger] : []), ...(meleeWeapons || []), ...(rangedWeapons || [])];
    const wargear = warrior.Equipment?.filter((weapon) => weapon.EquipmentType === "Wargear");
    return <>
        {allSorted && allSorted.length > 0 ? <table className="new-pdf-weapon-table">
            <thead>
                    <tr>
                        <th rowSpan={2} className="text-left">Weapon</th>
                        <th className="text-center">Range</th>
                        <th className="text-center">Strength</th>
                        <th colSpan={2} className="text-center">Traits</th>
                    </tr>
                </thead>
                <tbody>
                    {allSorted && allSorted.map((weapon) => <tr key="">
                        <td className="text-left">{weapon.Quantity > 1 ? `${weapon.Quantity}x ` : ""} {weapon.EquipmentName}</td>
                        <td>{weapon.Distance || "Melee"}</td>
                        <td>{weapon.Strength}</td>
                        <td colSpan={2} className="text-left">{weapon.Traits.replace(";", ", ") || "-"}</td>
                    </tr>)}
                </tbody>
        </table> : undefined}
        {wargear && wargear.length > 0 ?
            wargear.map((warg) => <div key="" style={{ width: "100%", margin: "0em 0em 0em 0em", padding: "0em 0em 0.5em 0em", display: "flex", justifyContent: "space-evenly", flexDirection: "column", textWrap: "balance" }}>
                    <div style={{ fontWeight: "bold" }}>{warg.EquipmentName} {warg.ArmourValue ? ` (${7 - warg.ArmourValue}+ AS)`: ""}</div>
                    <div >{traits.find(t => t.Trait === warg.Traits)?.Text || warg.Traits}</div>
                </div>)
            : null
        } 
    </>;
};