import { faCircleCheck, faCircleXmark, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { setCampaignUrl } from "../redux/slices/warbandSlice";

const urlRegex = new RegExp("/^(https?://)?([da-z.-]+).([a-z.]{2,6})([/w .-]*)*/?$/");
const numberRegex = new RegExp("\\d+$");
export const UrlInput = () => {
    return <TextInputControl label= "Campaign Url" dispatchCommand={setCampaignUrl} regex={new RegExp(urlRegex)} />;
};

export const TextInputControl = ({label, dispatchCommand, regex, prefilled} : {label: string; dispatchCommand: (val: string) => void; regex: RegExp; prefilled?: string}) => {
    const [text, setText] = useState<string>(prefilled || "");
    const onChangeHandler = (txt: string) => {
        setText(txt);
        if (regex.test(txt)) {
            dispatchCommand(txt);
        } else {
            dispatchCommand("");
        }
    };
    return <div className="input-container">
        <label className="input-label">{label}</label>
        <input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value)}
            placeholder={prefilled || "Start typing..." }
            className="input-field" />
            <FontAwesomeIcon icon={regex.test(text) ? faCircleCheck : faTriangleExclamation} className={regex.test(text) ? "input-icon input-icon-ok" : "input-icon input-icon-not-ok"} />
        </div>;
};

export const NumberInputControl = ({label, dispatchCommand, prefilled} : {label: string; dispatchCommand: (val: number) => void; prefilled?: string}) => {
    const [value, setValue] = useState<string>(prefilled || "");
    const onChangeHandler = (val: string) => {
        setValue(val);
        if (numberRegex.test(val)) {
            dispatchCommand(parseInt(val));
        } else {
            dispatchCommand(0);
        }
    };
    return <div className="input-container">
        <label className="input-label">{label}</label>
        <input
            id={`${label}_nr_input_ctrl`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value)}
            placeholder={prefilled || "Start typing..." }
            className="input-field" />
            <FontAwesomeIcon icon={numberRegex.test(value) ? faCircleCheck : faCircleXmark} className={numberRegex.test(value) ? "input-icon input-icon-ok" : "input-icon input-icon-not-ok"} />
        </div>;
};
