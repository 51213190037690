import { useAppSelector } from "../../redux/store";
import { IWarrior } from "../../types/warrior";

export const WarriorRules = ({ givenWarrior }: { givenWarrior: IWarrior }) => {
    const tempWarrior = useAppSelector((state) => state.tempwarrior);
    const warrior = givenWarrior || tempWarrior;
    return <>
        {warrior.Rules?.length ?
            <div style={{ width: "100%", margin: "0em 0em 0em 0em", padding: "0em 0em 0.5em 0em", display: "flex", justifyContent: "space-evenly", flexDirection: "column", textWrap: "balance" }}>
                <div style={{ fontSize: "0.75em", fontWeight: "bold", paddingTop: "0.2em" }}>Rules</div>
                {(warrior.Rules?.map((rule) => `${rule.RuleName}`) || []).join(", ")}
            </div> :
            null
        }
    </>;
};

export const WarriorPdfRules = ({ givenWarrior }: { givenWarrior: IWarrior }) => {
    const tempWarrior = useAppSelector((state) => state.tempwarrior);
    const warrior = givenWarrior || tempWarrior;
    return <>
        {warrior.Rules?.length ?
            <div style={{ width: "100%", margin: "0em 0em 0em 0em", padding: "0em 0em 0.5em 0em", display: "flex", justifyContent: "space-evenly", flexDirection: "column", textWrap: "balance" }}>
                {(warrior.Rules?.map((rule) => rule.RuleName === "Priest" || rule.RuleName === "Wizard" ?
                    <div key={rule.RuleName}>
                        {warrior.Spells?.map((spell) =>
                            <div key={spell.SpellName}>
                                <div style={{ fontWeight: "bold" }}>{spell.SpellName} ({spell.Difficulty})</div>
                                <div>{spell.Text}</div>
                            </div>)}
                    </div>
                    : <div key={rule.RuleName} >
                        <div style={{ fontWeight: "bold" }}>{rule.RuleName} </div>
                        <div >{rule.Text}</div>
                    </div>) || null)}
            </div> :
            null
        }
    </>;
};