import { faMedal, faCross, faHatWizard, faStar, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeroType, IWarrior } from "../../types/warrior";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { addWarbandLog, changeFunds, loadWarrior, updateWarrior } from "../../redux/slices";
enum WarriorModifyOptions {
    "Modify Equipment" = "Modify Equipment",
    "Modify Skills" = "Modify Skills",
    "Modify Stats / Name" = "Modify Stats / Name",
    "Modify Experience" = "Modify Experience",
    "Modify Spells" = "Modify Spells",
    "Add Injuries" = "Add Injuries",
    "Promote Warrior" = "Promote Warrior",
    "Kill Warrior" = "Kill Warrior",
    "Buy warrior to group" = "Buy warrior to group",
    "Add warrior to group" = "Add warrior to group",
    "Remove warrior from group" = "Remove warrior from group"
}
export const WarriorActions = ({ givenWarrior, readOnly }: { givenWarrior?: IWarrior; readOnly: boolean }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const warrior = givenWarrior || useAppSelector((state) => state.tempwarrior);
    const warband = useAppSelector((state) => state.warband);
    const isLeader = warrior.Rules?.find((rule) => rule.RuleName === "Leader");
    const isPriest = warrior.Rules?.find((rule) => rule.RuleName === "Priest");
    const isWizard = warrior.Rules?.find((rule) => rule.RuleName === "Wizard");
    const onChangeHandler = (option: string) => {
        dispatch(loadWarrior(warrior));
        const selectionElement = document.getElementById(warrior.Name);
        switch (option) {
            case WarriorModifyOptions["Modify Equipment"]:
                navigate("/warrior-equipment");
                break;
            case WarriorModifyOptions["Modify Skills"]:
                navigate("/warrior-rules");
                break;
            case WarriorModifyOptions["Modify Stats / Name"]:
                navigate("/warrior-main");
                break;
            case WarriorModifyOptions["Modify Experience"]:
                navigate("/warrior-experience");
                break;
            case WarriorModifyOptions["Modify Spells"]:
                navigate("/warrior-spells");
                break;
            case WarriorModifyOptions["Add Injuries"]:
                navigate("/warrior-injuries");
                break;
            case WarriorModifyOptions["Add warrior to group"]:
                dispatch(changeFunds(-warrior.TotalCost));
                dispatch(addWarbandLog([{ command: "Add warrior to group", value: warrior.Name, cost: warrior.TotalCost }]));
                dispatch(updateWarrior({...warrior, HeadCount: warrior.HeadCount + 1}));
                if (selectionElement) {
                    (selectionElement as HTMLSelectElement).selectedIndex = 0;
                }
                break;
                case WarriorModifyOptions["Buy warrior to group"]:
                    dispatch(changeFunds(-(warrior.TotalCost + (warrior.Experience * 2))));
                    dispatch(addWarbandLog([{ command: "Add warrior to group", value: warrior.Name, cost: warrior.TotalCost }]));
                    dispatch(updateWarrior({...warrior, HeadCount: warrior.HeadCount + 1}));
                    if (selectionElement) {
                        (selectionElement as HTMLSelectElement).selectedIndex = 0;
                    }
                    break;
            case WarriorModifyOptions["Remove warrior from group"]:
                dispatch(updateWarrior({...warrior, HeadCount: warrior.HeadCount - 1}));
                if (selectionElement) {
                    (selectionElement as HTMLSelectElement).selectedIndex = 0;
                }
                break;
            case WarriorModifyOptions["Kill Warrior"]:
                navigate("/warrior-delete");
                break;
            case WarriorModifyOptions["Promote Warrior"]:
                navigate("/warrior-promote");
                break;
            default:
                break;
        }
    };
    const filterActions = (action: string) => {
        if (warrior.Hero) {
            if (action === WarriorModifyOptions["Add warrior to group"]) {
                return false;
            }
            if (action === WarriorModifyOptions["Buy warrior to group"]) {
                return false;
            }
            if (action === WarriorModifyOptions["Promote Warrior"]) {
                return false;
            }
            if (action === WarriorModifyOptions["Remove warrior from group"]) {
                return false;
            }
        } else {
            if (action === WarriorModifyOptions["Modify Skills"]) {
                return false;
            }
            if (action === WarriorModifyOptions["Add Injuries"]) {
                return false;
            }
            if (action === WarriorModifyOptions["Modify Spells"]) {
                return false;
            }
        }
        return true;
    };
    const isDisabled = (entry: string) => {
        const isPriest = warrior.Rules?.find((rule) => rule.RuleName === "Priest");
        const isWizard = warrior.Rules?.find((rule) => rule.RuleName === "Wizard");
        if (warrior.FixedEquipment && entry === WarriorModifyOptions["Modify Equipment"]) {
            return true;
        }
        if (!isPriest && !isWizard && entry === WarriorModifyOptions["Modify Spells"]) {
            return true;
        }
        if (warrior.HeadCount > 1 && entry === WarriorModifyOptions["Kill Warrior"]) {
            return true;
        }
        if (warrior.HeadCount < 2 && entry === WarriorModifyOptions["Remove warrior from group"]) {
            return true;
        }
        if (entry === WarriorModifyOptions["Buy warrior to group"] && warband.cash < (warrior.TotalCost + (warrior.Experience * 2))) {
            return true;
        }
        return false;
    };
    return <>
        <div style={{ position: "absolute", right: "0.4em", bottom: "1.5em", height: "1.5em" }}>
            {!readOnly ? <select id={warrior.Name} key={warrior.Name} style={{ appearance: "none", border: "none", fontSize: "1.5em", padding: "0.3em", width: "1.5em", background: "transparent" }} onChange={(e) => onChangeHandler(e.target.value)}>
                <option selected={true} disabled={true} key="..." value="..." label="..." />
                {Object.keys(WarriorModifyOptions).filter((entry) => filterActions(entry)).map((entry) => <option key={entry} value={entry} label={entry} disabled={isDisabled(entry)}/>)}
            </select> : null}
        </div>
        {
            warrior.Hero ?
                <div className="warrior-hero-icon">
                    {isLeader ? <FontAwesomeIcon icon={faMedal} style={{ width: "1.5em" }} /> : null}
                    {isPriest ? <FontAwesomeIcon icon={faCross} style={{ width: "1.5em" }} /> : null}
                    {isWizard ? <FontAwesomeIcon icon={faHatWizard} style={{ width: "1.5em" }} /> : null}
                    {warrior.Hero === HeroType.HiredSword ? <FontAwesomeIcon icon={faUserShield} style={{ width: "1.5em" }} /> : <FontAwesomeIcon icon={faStar} style={{ width: "1.5em" }} />}
                </div> :
                null
        }
    </>;
};

